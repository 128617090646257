import React from 'react'
import PropTypes from 'prop-types'

import ActivityForm from "../components/ActivityForm"
import { useNavigate, useParams } from 'react-router-dom'

const ActivityDetails = () => {
  const navigate = useNavigate()
  const {super_activity_id} = useParams()
  return (
    <>
        <ActivityForm 
          onSuccess={()=>{navigate(
            `/activities${
              super_activity_id ?
              `/${super_activity_id}/activities` 
              : ""
            }`)}}
        />
    </>
    
  )
}


export default ActivityDetails