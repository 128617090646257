

import  {useQuery, UseQueryResult} from "react-query"
import { axios } from "lib/axios"
import { ExtractFnReturnType , QueryConfig } from "lib/react-query"
import {User} from "../type"
import { SiJquery } from "react-icons/si"

type getUsersQueryType = {
    limit ?: number , 
    page ?: number , 
    search ?: string,
    type?: any,
}


export const getUsers = async  (query : getUsersQueryType ={}) :Promise<{ count : number ,  users : User[]}> =>{
    console.log("our query is  ", query)
    
    const response = await   axios.get(
        "/admin/user",
        {
            params : {
                ...query
            }
        }
    )

    const data  =  response.data
    return (
        data
    )


}


type FnType  =  typeof getUsers;





type  useUsersOptions = {
    config? :any , 
    query?: getUsersQueryType
}


export const  useUsers :({config , query}:useUsersOptions)=>UseQueryResult<{count : number , users : User[]}, Error> = ({config , query} = {})=>{
    
    
    return (
        useQuery(
            ["users"] , 
            ()=>getUsers(query) , 
            config
        )
    )

}

