import React from 'react'
import PropTypes from 'prop-types'
import { Text, useColorModeValue } from '@chakra-ui/react'

type DateProps = {
    value : "string"
}


const Date = ({value} : DateProps) => {
    const textColor = useColorModeValue("gray.700" , "white")
    return (
      <Text 
      textTransform={"capitalize"}
      fontSize='sm' 
      color={textColor}
      >
        {
            value
        }
      </Text>
    )
}


export default Date