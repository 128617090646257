import React from 'react'
import PropTypes from 'prop-types'

const StoreIcon = (props:any) => {
  return (
    <svg  {...props} data-name="Group 200" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 38.299 38.299">
      <path id="Path_122" data-name="Path 122" d="M0,0H38.3V38.3H0Z" fill="none"/>
      <path id="Path_123" data-name="Path 123" d="M6.3,7.192H28.639a1.6,1.6,0,0,0,0-3.192H6.3a1.6,1.6,0,0,0,0,3.192Zm24.192,2.872a1.592,1.592,0,0,0-1.564-1.277H6.011a1.592,1.592,0,0,0-1.564,1.277l-1.6,7.979a1.6,1.6,0,0,0,1.564,1.915H4.7v7.979a1.6,1.6,0,0,0,1.6,1.6H19.065a1.6,1.6,0,0,0,1.6-1.6V19.958h6.383v7.979a1.6,1.6,0,1,0,3.192,0V19.958h.287a1.6,1.6,0,0,0,1.564-1.915ZM17.469,26.341H7.894V19.958h9.575Z" transform="translate(1.681 2.383)"/>
    </svg>

  )
}

StoreIcon.propTypes = {}

export default StoreIcon