import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, HStack, IconButton, LinkBox, LinkOverlay, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { useDeliveryPriceSettings } from '../api/getDeliveryPriceSettings'
import { DeliveryPriceSetting } from '../type'


//icons
import {AiOutlinePlus} from "react-icons/ai"
import { Link } from 'react-router-dom'
import If from 'common/If'
import PaymentStatistics from '../components/PaymentStatistics'

const DeliverySettings = () => {

    const { data: deliveryPriceSettings } =  useDeliveryPriceSettings({});


    const headerBg =  useColorModeValue("primary.500" , "primary.700")
    return (
        <Box>
            <PaymentStatistics />


            <HStack
                justifyContent={"space-between"}
                py={10}
            >
                <Text
                    color={"primary.500"}
                    fontSize={"lg"}

                >
                    Prix de laivraison
                </Text>
                <LinkBox>
                    <Link to="/delivery/newPrice" >
                        <LinkOverlay>
                            <IconButton 
                                variant={"primaryFill"} 
                                aria-label="new price setting" 
                                icon={<AiOutlinePlus/>} 
                            />
                        
                        </LinkOverlay>
                    </Link>
                </LinkBox>

            </HStack>
            <Flex gap={10} justifyContent="space-evenly">
                <Box
                    w={"50%"}
                >
                    <Card
                        position={"relative"}
                        pt={100}
                        overflow="hidden"
                    >
                        <Flex
                            bg={headerBg}
                            position="absolute"
                            top={0}
                            left={0}
                            w={"100%"}
                            h={70}
                            px={5}
                            alignItems="center"
                            gap={5}

                        >
                            <Text color="white" w="30%" px="2" >
                                nom
                            </Text>
                            <Text color="white" w="30%" px="2" >
                                Distance
                            </Text>
                            <Text color="white" w="30%" px="2" >
                                Prix 
                            </Text>
                        </Flex>
                            {
                                deliveryPriceSettings?.first?.map((deliveryPrice: DeliveryPriceSetting , key: any)=>{
                                    return(
                                        <Flex
                                            top={0}
                                            left={0}
                                            w={"100%"}
                                            h={70}
                                            px={5}
                                            alignItems="center"
                                            gap={5}
                                            key={key}
                                        >
                                            <Text  w="33%" px="2" >
                                                {deliveryPrice?.region_name}
                                            </Text>
                                            <Text w="33%" px="2" >
                                                {`${deliveryPrice?.radius} km`}
                                            </Text>
                                            <Text  w="33%" px="2" >
                                                {`${deliveryPrice?.price} Da`} 
                                            </Text>
                                        </Flex>
                                    )
                                })
                            }

                    </Card>

                    
                </Box>

                <If condition={deliveryPriceSettings?.second}>
                    <Box
                        w={"50%"}
                    >
                        <Card
                            position={"relative"}
                            pt={100}
                            overflow="hidden"
                        >
                            <Flex
                                bg={headerBg}
                                position="absolute"
                                top={0}
                                left={0}
                                w={"100%"}
                                h={70}
                                px={5}
                                alignItems="center"
                                gap={5}

                            >
                                <Text color="white" w="30%" px="2" >
                                    nom
                                </Text>
                                <Text color="white" w="30%" px="2" >
                                    Distance
                                </Text>
                                <Text color="white" w="30%" px="2" >
                                    Prix 
                                </Text>
                            </Flex>
                                {
                                    deliveryPriceSettings?.second?.map((deliveryPrice: DeliveryPriceSetting , key: any)=>{
                                        return(
                                            <Flex
                                                top={0}
                                                left={0}
                                                w={"100%"}
                                                h={70}
                                                px={5}
                                                alignItems="center"
                                                gap={5}
                                                key={key}
                                            >
                                                <Text  w="33%" px="2" >
                                                    {deliveryPrice?.region_name}
                                                </Text>
                                                <Text w="33%" px="2" >
                                                    {`${deliveryPrice?.radius} km`}
                                                </Text>
                                                <Text  w="33%" px="2" >
                                                    {`${deliveryPrice?.price} Da`} 
                                                </Text>
                                            </Flex>
                                        )
                                    })
                                }

                        </Card>

                        
                    </Box>

                </If>

        
            </Flex>

        </Box>
            
    )
}


export default DeliverySettings