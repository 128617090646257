import  {nanoid} from "nanoid"
import store from "zustand"
import {toast} from "react-toastify"



export type Notification = {
    type : "success" | "warn" | "info" | "error" , 
    id : string , 
    title?: string , 
    message : string ,
    isNew?: boolean
}


export type NotificationsStore = {
    notifications : Notification[] , 
    addNotification :  (notification:Omit<Notification , "id">)=>void , 
    dismissNotification : (id:string)=>void
}



export const useNotification = store<NotificationsStore>((set)=>({
    notifications  :[],
    addNotification:(notification)=>{
        const id = nanoid()
        set((state)=>({
            notifications : [
                ...state.notifications , 
                {
                    id  , 
                    ...notification
                }
            ]
        }))

        setTimeout(()=>{
            toast[notification.type](notification.message ,{containerId : id 
            })
        } , 500)



        

    },
    dismissNotification:(id:string)=>{
        set((state)=>({
            notifications :  state.notifications.filter(notification=>notification.id!=id)
        }))
    }


}))