import React from 'react'
import PropTypes from 'prop-types'
import { CustomCardProps } from 'lib/theme'
import { forwardRef , chakra, useStyleConfig } from '@chakra-ui/react'




const Card = forwardRef<CustomCardProps, 'div'>((props , ref)=>{
    const { size, variant, ...rest } = props;
	const styles = useStyleConfig('Card', { size, variant });

	return <chakra.div ref={ref} __css={styles} {...rest} />;
})



export default Card