

export const storesColumns = [
    {
        Header : "" , 
        Type : "image",
        accessor : "storePicture"
    },
    {
        Header :"store name",
        accessor : "store_name",
    }, 
    {
        Header :"store activity",
        accessor : "activity_name",
    }, 
    {
        Header :"owner name",
        accessor : "user_name",
    }
    

]