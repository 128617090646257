import React from 'react'

const DeleteIcon = () => {
  return (
    <svg id="Group_142" data-name="Group 142" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.436 36.537">
  <path id="Path_86" data-name="Path 86" d="M126.255,70.6c-.228-.856-.38-1.332-.38-1.332-.247-.885-.875-.885-1.807-1.037l-5.051-.637c-.628-.1-.628-.1-.875-.647-.828-1.864-1.084-2.949-1.988-2.949h-9.8c-.9,0-1.151,1.084-1.979,2.958-.247.533-.247.533-.875.647l-5.061.637c-.923.152-1.589.238-1.836,1.122,0,0-.114.39-.352,1.237-.3,1.132-.428,1.008.618,1.008h28.765C126.683,71.619,126.569,71.734,126.255,70.6Z" transform="translate(-96.038 -64)" />
  <path id="Path_87" data-name="Path 87" d="M140.978,176H117.5c-1.579,0-1.655.209-1.56,1.4l1.779,23.077c.152,1.17.266,1.408,1.665,1.408h19.71c1.4,0,1.512-.238,1.665-1.408l1.779-23.077C142.634,176.2,142.557,176,140.978,176Z" transform="translate(-114.024 -165.346)" />
</svg>

  )
}

export default DeleteIcon