

import  {useQuery, UseQueryResult} from "react-query"
import { axios } from "lib/axios"
import { ExtractFnReturnType , QueryConfig } from "lib/react-query"
import { Deliver } from "../type"
import { extractDelivers } from "../utils/extractData"

type getDeliversQueryType = {
    limit ?: number , 
    page ?: number , 
    search ?: String
}


export const getDelivers = async  (query : getDeliversQueryType ={}) :Promise<{ count : number ,  delivers : Deliver[]}> =>{
    const response = await   axios.get(
        "/admin/user/deliver",
        {
            params : {
                ...query
            }
        }
    )

    const data  =  response.data
    return (
        data
    )


}


type FnType  =  typeof getDelivers;





type  useUsersOptions = {
    config? :any , 
    query?: getDeliversQueryType
}


export const  useDelivers :({config , query}:useUsersOptions)=>UseQueryResult<{count : number , delivers : Deliver[]}, Error> = ({config , query} = {})=>{
    return (
        useQuery(
            ["delivers"] , 
            ()=>getDelivers(query) , 
            config
        )
    )

}

