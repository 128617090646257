
import { Box } from "@chakra-ui/react"
import Layout from "../components/Layout"
import UsersList from "../components/UsersList"



const ListUsers =() => {

  return (
    <>
      <UsersList />
    </>
  )
}


export default ListUsers