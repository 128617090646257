import React from 'react'
import PropTypes from 'prop-types'
import { Stack } from '@chakra-ui/react'
import DeliveryPriceSettingMap from '../components/DeliveryPriceSettingMap'

const NewDeliveryPriceSetting = () => {
  return (
    <Stack>
        <div style={{ height: '70vh', width: '100%' }}>
          <DeliveryPriceSettingMap />
        </div>
    </Stack>
  )
}
export default NewDeliveryPriceSetting