import { Category, Product, Store } from "../type";



const getPhoneNumber = (user:any)=>{
    let authAccounts = user.auth_accounts


    let authAccount =  authAccounts.find((el:any)=>el.auth_account_type==="phoneNumber")


    return (authAccount?.auth_account)

}




export const  extracStores : (stores : any)=> Store[]  =  (stores :  any) =>{
    
    return (
        stores.map((store : any)=>{
            return (
                {
                    id : store.store_id, 
                    storePicture : store?.file , 
                    store_name :  store?.store_name ?? "" , 
                    user_name  : `${store?.user?.user_firstname ?? ""} ${store?.user?.user_lastname ?? ""} `, 
                    activity_name : store?.activity?.activity_name ?? ""


                }
            )

        })
    )

   

} 




export const  extractStore : (user : any)=> Store  =  (store:  any) =>{
    
    return (
        {
            id : store.store_id, 
            storePicture : store?.file , 
            store_name :  store?.store_name ?? "" , 
            user_name  : `${store?.user?.user_firstname ?? ""} ${store?.user?.user_lastname ?? ""} `, 
            activity_name : store?.activity?.activity_name ?? "",
            user: store?.user,
            mainActivity: store?.activity?.superActivity?.activity_name,
            has_category: store?.activity?.activity_has_category,
            store_activated: store?.store_activated


        }
    )

    
    

   

} 



export const extractCategories : (categories : any)=> Category[]  =  (categories :  any) =>{
    
    return (
        categories.map((category : any)=>{
            return (
                {
                    id : category.category_id, 
                    category_name: category?.category_name ??"NO",
                    iconPicture: category?.icon?.file
                }
            )

        })
    )

   

} 

export const extractProducts : (products : any)=> Product[]  =  (products :  any) =>{
    
    return (
        products.map((product : any)=>{
            return (
                {
                    id : product.product_id, 
                    product_name: product?.product_name ??"NO",
                    productPicture: product?.file
                }
            )

        })
    )

   

} 