import { Route, Routes } from "react-router-dom"
import ListUsers from "../pages/ListUsers"
import UserDetails from "../pages/UserDetails"


export const UserRoutes = ()=>{
    return(
        <Routes>
            <Route  path="/" element={<ListUsers />} />
            <Route  path='/:user_id' element={<UserDetails />} />
        </Routes>
    )
}