import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, HStack, Icon, Img, LinkBox, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { Link, NavLink } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa'

type AuthLayoutProps = {
  children : ReactNode
}


const AuthLayout = ({
  children
}:AuthLayoutProps
) => {
  return (
    <Flex
      position={"relative"}
    >
      <Flex
        h={{
					sm: 'initial',
					md: 'unset',
					lg: '100vh',
					xl: '97vh'
				}}
				w='100%'
				maxW={{ md: '66%', lg: '1313px' }}
				mx='auto'
				pt={{ sm: '50px', md: '0px' }}
				px={{ lg: '30px', xl: '0px' }}
				ps={{ xl: '70px' }}
				justifyContent='start'
				direction='column'
      
      >
        <Box
          position={"absolute"}
          right={0}
          minH={'100vh'}
          h='100%'
          w={{
            lg : "50vw" , 
            "2xl" : "44vw"
          }}
          borderBottomStartRadius={{
            lg :"120px" , 
            "xl" : "200px"
          }}
          overflow={"hidden"}
          backgroundImage={"/assets/images/auth_login.jpg"}
          backgroundSize='cover'
          backgroundPosition={'center'}
        >
          
        </Box>
        <Box
          px={{base : 10, md : 20}}
          py={20}
          w={{ base: "100%", md: "520px" }}
        >
          <LinkBox>
            <Link
              to='/'
            >
              <HStack>
                <Icon 
                color='secondaryGray.500'
                  as={FaChevronLeft}
                  h="13px"
                  w="8px"
                />
                <Text
                  color="secondaryGray.500"
                  fontSize={"sm"}
                >
                  back to home
                </Text>
              </HStack>
            </Link>

          </LinkBox>
          {
            children
          }

        </Box>
      </Flex>

    </Flex>
  )
}


export default AuthLayout