

import  {useQuery, UseQueryResult} from "react-query"
import { axios } from "lib/axios"
import { ExtractFnReturnType , QueryConfig } from "lib/react-query"
import {Store} from "../type"

type getUsersQueryType = {
    limit ?: number , 
    page ?: number , 
    search ?: string,
    store_activated?: any,
}


export const getStores = async  (query : getUsersQueryType ={}) :Promise<{ count : number ,  stores : Store[]}> =>{
    const response = await   axios.get(
        "/admin/user/store",
        {
            params : {
                ...query
            }
        }
    )

    const data  =  response.data
    return (
        data
    )


}


type FnType  =  typeof getStores;





type  useUsersOptions = {
    config? :any , 
    query?: getUsersQueryType
}


export const  useStores :({config , query}:useUsersOptions)=>UseQueryResult<{count : number , stores : Store[]}, Error> = ({config , query} = {})=>{
    return (
        useQuery(
            ["stores"] , 
            ()=>getStores(query) , 
            config
        )
    )

}

