import React from 'react'
import PropTypes from 'prop-types'

const PaymentIcon = (props: any) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 33.541 33.541">
  <path id="Path_127" data-name="Path 127" d="M29.666,12.9A16.771,16.771,0,1,0,46.436,29.666,16.789,16.789,0,0,0,29.666,12.9Zm5.269,21.893a4.976,4.976,0,0,1-2.04,1.706,6.386,6.386,0,0,1-2,.524v2.254a.621.621,0,0,1-.621.621h-1.86a.621.621,0,0,1-.621-.621V36.823a5.856,5.856,0,0,1-1.041-.364,4.629,4.629,0,0,1-2.205-1.828,5.091,5.091,0,0,1-.855-2.738.622.622,0,0,1,.579-.646l1.67-.114a.622.622,0,0,1,.66.547,2.857,2.857,0,0,0,.456,1.338,2.242,2.242,0,0,0,1.1.856,4.736,4.736,0,0,0,3.376.11,2.2,2.2,0,0,0,.909-.637,1.332,1.332,0,0,0,.25-.8,1.076,1.076,0,0,0-.249-.719,2.161,2.161,0,0,0-.956-.6c-.305-.1-1.1-.327-2.307-.619a12.623,12.623,0,0,1-2.733-.894,4.228,4.228,0,0,1-1.676-1.474,3.737,3.737,0,0,1-.549-2,4.219,4.219,0,0,1,.664-2.267A4.325,4.325,0,0,1,26.843,22.4a5.136,5.136,0,0,1,.949-.315V20.017a.621.621,0,0,1,.621-.621h1.86a.621.621,0,0,1,.621.621v1.963a7.519,7.519,0,0,1,1.547.421,4.526,4.526,0,0,1,2.038,1.683,4.656,4.656,0,0,1,.784,2.435.622.622,0,0,1-.58.651l-1.71.115a.62.62,0,0,1-.658-.536,2.172,2.172,0,0,0-.717-1.5,2.939,2.939,0,0,0-1.97-.55,3.361,3.361,0,0,0-1.976.478,1.124,1.124,0,0,0-.478.95.91.91,0,0,0,.284.714,6.584,6.584,0,0,0,2.411.806,21.607,21.607,0,0,1,3.117.91,4.289,4.289,0,0,1,2,1.618,3.892,3.892,0,0,1,.658,2.218A4.323,4.323,0,0,1,34.934,34.788Z" transform="translate(-12.895 -12.895)" />
</svg>

  )
}


export default PaymentIcon