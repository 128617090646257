

import { Flex , Image, LinkBox, Stack } from "@chakra-ui/react"
import Logo from "assets/logo.png"
import { HSeparator } from "components/separator/Seperator"
import { Link } from "react-router-dom"

const SideBarBrand = () => {
  return (
    <Flex
        alignItems="center"
        direction={"column"}
    >
        <LinkBox>
        <Link
            to="/"
        >
                <Image 
                    src={Logo}
                    w={150}
                    my='30px'
                    cursor={"pointer"}
                />
        </Link>
        
        </LinkBox>
        <HSeparator mt={"20px"} />
    </Flex>
  )
}


export default SideBarBrand