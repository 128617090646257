import {useState , useEffect} from "react"
import Card from "components/card/Card"
import TableComponent from "components/table/Table"
import { extracStores } from "../utils/extractdata"
import {storesColumns } from "../variable/table"
import {useNavigate } from "react-router-dom"
import { Store } from "../type"
import { useStoresContext } from "contexts/storesContext"

const limit  =  5 ; 

const StoresList =()=> {
  const {stores,getStores , pageIndex , setSearch, setPageIndex} =  useStoresContext()
  const navigate = useNavigate()
  const [pageCount , setPageCount] = useState(1)
 

  useEffect(()=>{
    const count  = stores?.count ?? 1
    setPageCount(Math.ceil(count/limit))
  } , [stores])



  const navigateUserDetails = (row:Store)=>{
    navigate(`/stores/${row.id}`)

  }


  return (
      <Card>
        <TableComponent<Store>
            name="stores"
            selectRow={navigateUserDetails}
            data={extracStores(stores?.stores ??[])}
            tableColumns={storesColumns}
            setPageIndex={setPageIndex}
            pageIndex={pageIndex}
            pageCount={pageCount}
            searching={setSearch}
          />
      </Card>
  )
}


export default StoresList