import { Box, Collapse, Flex, HStack, Icon, Text, useColorModeValue } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { NavLink, useLocation } from "react-router-dom"
import { menuItemsType } from "types/routes"
import SideBarSubLinks from "./SideBarSubLinks"

type SideBarLinksProps = {
  menuItems : menuItemsType[]
}


const SidebarLinks = (
  {
    menuItems 
  } :SideBarLinksProps

) => {
  let location = useLocation()
  let activeColor = useColorModeValue("gray.900" , "white");
  let activeIcon = useColorModeValue("primary.500" , "white")
  let textColor = useColorModeValue("gray.700" , "white" )
  let brandColor= useColorModeValue('primary.500' , "primary.400")



  // verify that the menu is active or not
  const activeMenu = (menuPath:string)=>{
    return (location.pathname.includes(menuPath))
  }


  return (
    <>
      {
        menuItems.map((menuItem :menuItemsType , key:any)=>{
          if(menuItem.type === "title"){
            return (
              <Text
                my={2}
                textTransform="uppercase"
                fontSize={"sm"}
                fontWeight="bold"
              >
                {
                  menuItem.name && 
                  <FormattedMessage id={menuItem.name} />
                }
                
              </Text>
            )

          }else{
            return(
              <NavLink
                key={key}
                to={menuItem?.path ?? "/"}
              >
                <Box
                
                >
                  <HStack
                    spacing={'22px'}
                    py={"5px"}
                    ps={"10px"}
                  >
                    <Flex
                      w="100%"
                      alignItems={"center"}
                      justifyContent="center"
                    >
                      <Box
                        me="18px"
                        color={activeMenu(menuItem?.activeKey??"") ? activeIcon :  textColor}
                      >
                        <Icon width="20px" height='20px'  fill={activeMenu(menuItem?.activeKey??"") ? activeIcon :  textColor} as={menuItem?.icon}  /> 
                      </Box>
                      <Text
                        me="auto"
                        color={activeMenu(menuItem?.activeKey??"") ? activeColor :  textColor}
                        fontWeight={activeMenu(menuItem?.activeKey??"") ? "semibold" :  "normal"}
                        fontSize="md"
                        textTransform={"capitalize"}
                        
                          
                      >
                        {
                          menuItem.name && 
                          <FormattedMessage id={menuItem.name} />
                        }
                      </Text>
                    </Flex>
                    <Box
                          h='36px'
                          w='4px'
                          bg={activeMenu(menuItem?.activeKey?.toLocaleLowerCase()??"") ? brandColor : 'transparent'}
                          borderRadius='5px'
                      />
                  </HStack>
                  <Collapse
                    in={activeMenu(menuItem?.activeKey??"")}
                  >
                    <SideBarSubLinks 
                      menuItems={menuItem?.children ?? []}
                    />
                  </Collapse>
                </Box>
    
              </NavLink>
            )
          }
        })
      }
    </>
  )
}

export default SidebarLinks