import React from 'react'
import PropTypes from 'prop-types'
import { Button, Flex, Icon, useColorMode, useColorModeValue } from '@chakra-ui/react'
import SearchBar from './SearchBar'

import  {IoMdMoon , IoMdSunny} from "react-icons/io"


const NavbarLinks = () => {
	const { colorMode, toggleColorMode } = useColorMode();

  let navbarIcon =  useColorModeValue("gray.900" , "white")
	let menuBg = useColorModeValue('white', 'dark.800');


  return (
    <Flex
      alignItems={"center"}
      bg={menuBg}
      p='10px'
			borderRadius='30px'
    >
      <SearchBar
	  	searching={()=>{}}
		me='10px'
		borderRadius='30px'
        variant=''
      />
      <Button
				variant='no-hover'
				bg='transparent'
				p='0px'
				minW='unset'
				minH='unset'
				h='18px'
				w='max-content'
				onClick={toggleColorMode}>
				<Icon
					me='10px'
					h='18px'
					w='18px'
					color={navbarIcon}
					as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
				/>
			</Button>
    </Flex>

  )
}


export default NavbarLinks