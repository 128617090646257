

export const deliversColumns = [
    {
        Header : "" , 
        Type : "image",
        accessor : "deliverPicture"
    },
    // {
    //     Header :"store name",
    //     accessor : "store_name",
    // }, 
    {
        Header :"deliver name",
        accessor : "deliver_name",
    },
    {
        Header :"phone number",
        accessor : "phoneNumber",
    }, 
    

]