import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Icon,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  Text,
  MenuGroup,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
  HStack,
  Center
} from "@chakra-ui/react";
import Flag from "react-country-flag";
import { AsYouType ,  PhoneNumber} from "libphonenumber-js";
import { getCountry  , COUNTRIES} from "variables/countries"
import { UseFormRegisterReturn } from "react-hook-form";
import FieldWrapper, { FieldWrapperPassThroughProps } from "./FieldWrapper";
import { inputStyles } from "lib/theme/components/input";
import usePhoneNumber from "hooks/usePhoneNumber";


type InputFieldProps = FieldWrapperPassThroughProps  & {
  registration : Partial<UseFormRegisterReturn> , 
  inputStyle?: any , 
  defaultValue?:any , 
  options?: any , 
  country?:any,
  size?:string,
  setValue:any , 
  name:string,
}



export default function PhoneNumberInputField({
  size,
  country,
  options , 
  registration , 
  defaultValue,
  setValue , 
  name,
  error,
  label
}:InputFieldProps) {
  const  {
    number , 
    onCountryChange , 
    onPhoneNumberChange , 
    selectedCountry 
  } =  usePhoneNumber({
    options , 
    country ,
    defaultValue
  })


  useEffect( ()=>{
    setValue(name , number)
  }, [number])

  
  

  return (
    <FieldWrapper
      error={error}
      label={label}
    >
      
      <InputGroup 
        size={size} 
      
      >
        <Flex
          w="100%"
        >
          <InputLeftElement 
            width={100} 
            h= {"100%"}
            px={3}
          >
            <Menu>
              <MenuButton>
                <HStack
                >
                  <Text
                    textTransform={'capitalize'}
                  >
                    {selectedCountry?.name}
                  </Text>
                  <Flag 
                    countryCode={selectedCountry?.value || ""}
                    svg
                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                    cdnSuffix="svg"
                    title={selectedCountry?.value || ""}
                  />

                </HStack>

              </MenuButton>
              <Box
                position={"relative"}
                bg="red"
              >
                <MenuList
                >

                {options.map((option:any) => (
                    <MenuItem
                      onClick={()=>{onCountryChange(option)}}
                    >
                      <HStack>
                        <Flag 
                          countryCode={option.value || ""}
                          svg
                          cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                          cdnSuffix="svg"
                          title={option.value || ""}
                        />
                        <Text
                          textTransform={'capitalize'}
                        >
                          {option.name}
                        </Text>

                      </HStack>
                    </MenuItem>
                  )
                )
                }

                </MenuList>

              </Box>
            </Menu>
          </InputLeftElement>

          <Input
            pl={100}
            {...registration}
            type="tel"
            value={number}
            onChange={onPhoneNumberChange}
            {...inputStyles}
            variant="primary"
            border="2px solid"
            h={50}
          /> 

        </Flex>
      </InputGroup>
    
    </FieldWrapper>
  );
}

PhoneNumberInputField.defaultProps = {
  options: [
    {value :  "DZ"  , name : "algerie"}  ,  
    { value : "FR" , name : "france" },
    { value : "US" , name : "usa" }
  ],
  size: "md" , 
  country : {value :  "DZ"  , name : "algerie"}
  
};
