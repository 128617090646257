import { Deliver } from "features/delivers/type";
import { useContext, createContext, useState } from "react";


export const DeliverContext = createContext<{
  deliver?:  Deliver,
  updateDeliver: any,
  isUpdatingDeliver: boolean,
  isUpdatedDeliver: boolean,
}>({
  updateDeliver: ()=>{},
  isUpdatingDeliver: false,
  isUpdatedDeliver: false,
})


export const useDeliverContext = () => useContext(DeliverContext);
