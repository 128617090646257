import { useAuth } from "lib/auth";
import { useRoutes } from "react-router-dom"
import { commonRoutes } from "./common";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public"




export const AppRoutes = ()=>{


    const auth = useAuth()


    const routes = auth.user ? protectedRoutes : publicRoutes;
  
    const element = useRoutes([...commonRoutes , ...routes , ...protectedRoutes]);



    // const element = useRoutes([...protectedRoutes])


    return(
        <>{element}</>
    )


}