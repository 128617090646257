import React from 'react'
import PropTypes from 'prop-types'

const CommandeIcon = (props: any) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg"viewBox="0 0 31.68 33.558">
  <path id="Path_126" data-name="Path 126" d="M20.84,4.045l3.468,1.482L9.6,11.818l-.041.019L6.087,10.353ZM26.111,6.3,30.32,8.1,15.34,14.506a.708.708,0,0,0-.426.722.737.737,0,0,0,0,.083v7.574l-1.535-2.3a.708.708,0,0,0-.9-.241l-1.808.9v-8.34ZM32.12,8.868l3.473,1.485L20.84,16.661l-3.471-1.482L32.078,8.887l.041-.019ZM5,11.426l4.248,1.817V22.39a.708.708,0,0,0,1.023.633l2.273-1.137,2.447,3.67,0,.006a.708.708,0,0,0,.357.321l0,0q.031.013.064.022a.705.705,0,0,0,.13.028.738.738,0,0,0,.083,0,.723.723,0,0,0,.083-.006l.058-.011.041-.011.036-.011a.715.715,0,0,0,.116-.05l0,0a.709.709,0,0,0,.359-.711V16.273L20.132,17.9V37.6L5,31.129Zm31.68,0v19.7L21.548,37.6V17.9Z" transform="translate(-5 -4.045)" fill-rule="evenodd"/>
</svg>

  )
}


export default CommandeIcon