import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Box, Button, Input, Stack, Text } from '@chakra-ui/react'


import {UseFormRegisterReturn} from "react-hook-form"
import FieldWrapper from 'components/form/FieldWrapper'
import { Photo } from 'types/models'




type UserPhotoProps = {
  photo?:Photo,
  name?:string , 
  registration :  Partial<UseFormRegisterReturn> 
}

const UserPhoto = (
  {
    photo , 
    name="user",
    registration 
  } :UserPhotoProps
) => {
  const [imageToShow , setImageToShow] =  useState<Photo | null>(null)
  const handleImageEdit=(e:any)=>{
    if(e.target){
      const files = e.target.files
      const file_url = URL.createObjectURL(files[0])
      setImageToShow({
          file_url
      })
      
    }
  }

  useEffect(()=>{
    console.log('data is  ', photo)
  } , [photo])







  return (
    <Stack
      justifyContent={"center"}
      alignItems="center"
      p= {5}
      spacing={10}
    >
        <Avatar
          src={imageToShow? imageToShow?.file_url : photo?.file_url}
          size="2xl"
          name={name}

        />
        <Button
          variant="primaryOutline"
          px={5}
          py={6}
          fontSize="xs"
          fontWeight={500}
          position={"relative"}
          cursor="pointer"
        >
          <Text
            cursor="pointer"
          >
          changer la photo de profile
            
          </Text>
            <Input 
              type="file"
              position={"absolute"}
              opacity={0}
              {...registration}
              onChange={handleImageEdit}
              cursor="pointer"
              w={"100%"}
              h="100%"
              // multiple={true}
            />

          {/* </FieldWrapper> */}

        </Button>

    </Stack>
  )
}

export default UserPhoto