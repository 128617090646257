import { User } from "../type";


const extractPicture =  (user:any)=>{
    if(user.client){
        return (
            user?.client?.file 
        )
    }

    return (
        user?.store?.file
    )
}

const getPhoneNumber = (user:any)=>{
    let authAccounts = user.auth_accounts


    let authAccount =  authAccounts.find((el:any)=>el.auth_account_type==="phoneNumber")


    return (authAccount?.auth_account)

}




export const  extractUsers : (users : any)=> User[]  =  (users :  any) =>{
    
    return (
        users.map((user : any)=>{
            console.log("our data e ", new Date(user.createdAt))
            return (
                {
                    id : user.user_id, 
                    userPicture :extractPicture(user) , 
                    username :  user?.user_username ?? "" , 
                    type  : user.store ? "store" :  (user.deliver ? "deliver": "client") , 
                    phoneNumber : getPhoneNumber(user) , 
                    user_suspended : user.user_suspended ,
                    subscription_date : user?.createdAt


                }
            )

        })
    )

   

} 




export const  extractUser : (user : any)=> User  =  (user:  any) =>{
    
    return (
        {
            id : user.user_id, 
            userPicture :extractPicture(user) , 
            username :  user?.user_username ?? "" , 
            type  : user.store ? "store" :  "client" , 
            phoneNumber : getPhoneNumber(user) , 
            subscription_date : user?.createdAt , 
            user_firstname : user?.user_firstname ,
            user_lastname : user?.user_lastname   , 
            user_suspended : user.user_suspended ,
            displayName: `${user?.user_lastname} ${user?.user_firstname}`


        }
    )

    
    

   

} 