
import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react'
import If from 'common/If'
import { BiStore } from 'react-icons/bi'


// icons
import {FaRegUser} from "react-icons/fa"
import {SiStatuspal} from "react-icons/si"
import {MdOutlineDeliveryDining, MdStorefront} from "react-icons/md"
import  { CiDeliveryTruck }  from "react-icons/ci"


const colors:any ={
    store : "primary.500",
    client : "primary.500",
    deliver: "primary.500"
}
    

  
type UserTypeProps = {
    value : "store" | "client" | "deliver"
}

    
const icons:any = {
    store : BiStore  , 
    client : FaRegUser,
    deliver: CiDeliveryTruck,
    admin: FaRegUser
}
    
const UserType = (
    {
            value
        }:UserTypeProps
    ) => {
                

    const icon = icons[value] ?  icons[value] : SiStatuspal
    const color =   colors[value] ? colors[value] : "black"
    const textColor = useColorModeValue("gray.700" , "white")
    return (
        <Flex align='center'>
            <Icon
                w='24px'
                h='24px'
                me='5px'
                as={
                    icon
                }
                bg={color}
                color='white'
                borderRadius={"full"}
                p={1}
            />
            <Text 
                textTransform={"capitalize"}
                fontSize='sm' 
                fontWeight='700'
                color={textColor}
                px={2}
            >
                {value}
            </Text>
        </Flex>
    )
}


export default UserType