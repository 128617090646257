import {useState , useEffect} from "react"
import Card from "components/card/Card"
import TableComponent from "components/table/Table"
import { useUsers } from "../api/getUsers"
import { User } from "../type"
import { extractUsers } from "../utils/extractdata"
import {userColumns } from "../variable/table"
import { Navigate, useNavigate } from "react-router-dom"
import FilterUserForm from "./FilterUserForm"
import { filter } from "@chakra-ui/react"

const limit  =  5 ; 

const UsersList =()=> {
  const navigate = useNavigate()
  const [pageIndex , setPageIndex] = useState<number>(1)
  const [search , setSearch] = useState("")
  const [filters, setFilters] =  useState<any>({})
  const [pageCount , setPageCount] = useState(1)
  const {data: users , refetch: refetchUsers}  = useUsers({
    query : {
      limit :  5, 
      page : pageIndex , 
      type: {
        not: "admin"
      },
      search,
      ...filters
    }
  })

  useEffect(()=>{
    const count  = users?.count ?? 1
    setPageCount(Math.ceil(count/limit))
  } , [users])

  useEffect(()=>{
    refetchUsers()
  } , [pageIndex ,search, filters , refetchUsers])


  const navigateUserDetails = (row:User)=>{
    navigate(`/users/${row.id}`)

  }



  const handleRefilter = (filters: any) =>{
    setFilters(filters)
  }


  return (
      <Card>
        <TableComponent<User>
            name="users"
            selectRow={navigateUserDetails}
            data={extractUsers(users?.users ??[])}
            tableColumns={userColumns}
            setPageIndex={setPageIndex}
            pageIndex={pageIndex}
            pageCount={pageCount}
            searching={setSearch}
            FilterForm={FilterUserForm}
            refilter={handleRefilter}
          /> 
      </Card>
  )
}


export default UsersList