

import  {useQuery, UseQueryResult} from "react-query"
import { axios } from "lib/axios"
import { ExtractFnReturnType , QueryConfig } from "lib/react-query"
import {User} from "../type"
import { extractUser } from "../utils/extractdata"

type getUsersQueryType = {
    user_id?: string
}


export const getUser = async  (params : getUsersQueryType ={}) :Promise<User> =>{
    const response = await   axios.get(
        `/admin/user/${params?.user_id}`
    )

    const data  =  response.data
    return (
        extractUser(data)
    )


}


type FnType  =  typeof getUser;





type  useUsersOptions = {
    config? :any , 
    params?: getUsersQueryType
}


export const  useUser :({config , params }:useUsersOptions)=>UseQueryResult<User, Error> = ({config , params} = {})=>{
    
    
    return (
        useQuery(
            ["user"] , 
            ()=>getUser(params) , 
            config
        )
    )

}

