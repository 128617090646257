
import { Flex, Spinner } from "@chakra-ui/react";
import { AuthUser, AuthResponse, login } from "features/auth";
import { googleLogin, LoginGoogleCredentialdsDTO } from "features/auth/api/firebase";
import { getUser } from "features/auth/api/getUser";
import { RegisterCredentialsDTO , registerWithEmailAndPassword } from "features/auth/api/register";
import { initReactQueryAuth } from "react-query-auth";
import storage from "utils/storage";

interface LoginCredentails{
    type : "server" | "google" , 
    email?:string , 
    password?:string
}


const handleUserResponse =  async (data:AuthResponse)=>{
    const {token , ...userData} =  data
    const user = userData.data;
    storage.setToken(token)
    return user
}


const loadUser =  async ()=>{
    let x =0 ;
    if(storage.getToken()){
        const data = await getUser();
        return data
    }
    return null
}
const loginFn  = async(data : LoginCredentails )=>{
    const response =  await login(data)
    const user =  await handleUserResponse(response)
    return user
}


const registerFn =  async (data : RegisterCredentialsDTO )=>{
    const response = await registerWithEmailAndPassword(data);
    const user = await handleUserResponse(response);
    return user;
}

const logoutFn = async  ()=>{
    storage.clearToken()
}



const authConfig = {
    loadUser ,
    loginFn , 
    registerFn , 
    logoutFn  ,
    LoaderComponent(){
        return(
            <Flex
                w="100vh"
                h="100vh"
            >
                <Spinner />

            </Flex>
        )
    }
}



export const {AuthProvider , useAuth } = initReactQueryAuth
<
    AuthUser | null , 
    unknown ,
    LoginGoogleCredentialdsDTO , 
    RegisterCredentialsDTO

>(authConfig)