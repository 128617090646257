
import { Order } from "features/orders/types";
import { useContext, createContext, useState } from "react";


export const SocketContext = createContext<{
  socket?:  any,
}>({
})


export const useSocketContext = () => useContext(SocketContext);
