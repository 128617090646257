import React from 'react'
import PropTypes from 'prop-types'
import FieldWrapper, { FieldWrapperPassThroughProps } from './FieldWrapper'
import { Icon, Input, InputGroup, InputLeftAddon, InputLeftElement, InputRightElement, useDisclosure } from '@chakra-ui/react'
import { FiEye } from "react-icons/fi"
import {UseFormRegisterReturn} from "react-hook-form"
import If from 'common/If'
import { DateRangePicker } from 'rsuite'
import { defaultFn } from 'utils/functions'
import { watch } from 'fs'

type InputFieldProps = FieldWrapperPassThroughProps  & {
    registration : Partial<UseFormRegisterReturn> , 
    inputStyle?: any , 
    defaultValue?:any,
    type?:string,
    setValue?:any,
    value?:any,
    inputLeftAddon?:any
}


const DateRangePickerField = (
   {
    registration , 
    error , 
    label  , 
    setValue=defaultFn,
    value=null,
    defaultValue,
    type="text",
    inputStyle={},
    inputLeftAddon=null
   }:InputFieldProps

) => {
  const {isOpen: isShowPasswrd ,onToggle: OnToogleShowPassword  } =  useDisclosure()
  
  const handleChange = (data: any) =>{
    if(registration.name){
      setValue(registration?.name , data )
    }
  }
  
  return (
    <FieldWrapper
      error={error}
      label={label}
    >
       <InputGroup
         {...inputStyle}
       >
        <DateRangePicker
          format="yyyy-MM-dd HH:mm:ss"
          {...registration}
          onChange={handleChange}
          // value={[new Date('2022-02-01 00:00:00'), new Date('2022-05-01 23:59:59')]}
          value={value}
          // defaultCalendarValue={[new Date('2022-02-01 00:00:00'), new Date('2022-05-01 23:59:59')]}
        />
       </InputGroup>
    </FieldWrapper>
  )
}


export default DateRangePickerField