import React, { PropsWithChildren, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// import { messaging } from './firebase'
// import { getToken, isSupported } from 'firebase/messaging'
// import { getMessagingToken } from './firebase'
// import { getMessagingToken } from './firebase'

const FirebaseMessagingProvider = ({ children }: PropsWithChildren<any>) => {
    // const [tokenFound, setTokenFound] = useState<boolean>(false)
    useEffect(()=>{
        // async function tokenFunc(){
        //     const token = await getMessagingToken(setTokenFound)

        //     // console.log("toke is here ", token)
        //     return token
        // }

        // tokenFunc()
    }, [])
    return (
        <>
            {children}
        </>
    )
}


export default FirebaseMessagingProvider