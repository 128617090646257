import React, { PropsWithChildren } from "react";
import { IntlProvider } from "react-intl";
import fr from "translations/fr.json";
import en from "translations/en.json";

const LOCALS = {
  fr: "fr-ca",
  en: "en",
};

const TRANSLATIONS = {
  fr,
  en,
};

type TranslationProps = {
  locale: "fr" | "en";
};

const TranslationProvider = ({
  children,
  locale = "fr",
}: PropsWithChildren<TranslationProps>) => {
  return (
    <IntlProvider messages={TRANSLATIONS[locale]} locale={LOCALS[locale]}>
      {children}
    </IntlProvider>
  );
};

export default TranslationProvider;
