import { axios } from "lib/axios"
import { useMutation } from "react-query"







export const useDeleteAd= ()=>{

    
    return useMutation({
        onMutate: async (newReparation:any) => {
            
            // const reparationReq = new FormRequestBuilder(newReparation).getFormData()

            // // const reparation = await createReparation(reparationReq);

            // const reparation = await queryClient.setQueryData()


            // return reparation

            


        
        },
        // onError: (_, __, context: any) => {
        // if (context?.previousComments) {
        //     queryClient.setQueryData(
        //     ["comments", discussionId],
        //     context.previousComments
        //     );
        // }
        // },
        onSuccess: () => {
            // addNotification({
            //     type: "success",
            //     message: "Ad deleted",
            // });
        } , 
        mutationFn : deleteAd
    });
}




export const deleteAd  =async (query :any ):Promise<any>  =>{
   
    const profile =  await   axios.delete(
        `admin/ad`,
        {
            params: {
                ...query
            }
        }
    )
    return profile
    
}