import { Route, Routes } from "react-router-dom"
import OrdersList from "../pages/OrdersList"
import OrderDetails from "../pages/OrderDetails"

export const OredersRoutes = ()=>{
    return(
        <Routes>
            <Route path="/" element={<OrdersList />} />
            <Route path="/:order_id" element={<OrderDetails />} />
        </Routes>
    )
}