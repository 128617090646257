import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, HStack, Input, ListItem, Text, UnorderedList, useColorModeValue } from '@chakra-ui/react'
import {FieldError} from "react-hook-form"


type FieldWrapperProps = {
    children : ReactNode ,
    styles?: any ,  
    label?:string ,
    error?: FieldError | undefined ,
    required?:boolean
}



export type FieldWrapperPassThroughProps = Omit<
    FieldWrapperProps , 
    "styles" | "children"
>

const FieldWrapper = (
    {
        children   , 
        error ,
        label,        
        required=false ,
        styles = {}
    } : FieldWrapperProps
) => {
    
    const textColor =  useColorModeValue("black.700" , "white")
    const brandStars = useColorModeValue("primary.500" , "primary.400")
  return(
    <FormControl
        // mb='24px'
        isInvalid={error?.message!=undefined}
    
    >
        {
            label && 
            <FormLabel
                color={textColor}
                mb={"8px"}
                ms={"4px"}
                fontSize="sm"
                fontWeight={"500"}
            >
                
                   
                <Flex>
                    {label}
                    {required  && <Text color={brandStars}>*</Text>}
                </Flex>
                

            </FormLabel>
        }
        {children}
      
            <FormErrorMessage
                display="flex"
                alignItems={"center"}
                fontSize='13px'
                fontWeight="semibold"
            >
                <UnorderedList
                    fontSize={"xs"}
                    px={5}
                >
                    <ListItem
                        color="red.500"
                    >

                        {
                            error?.message 
                        }


                    </ListItem>
                </UnorderedList>


            </FormErrorMessage>
           
        
    </FormControl>
  )
}

FieldWrapper.propTypes = {}

export default FieldWrapper