import React from 'react'
import PropTypes from 'prop-types'
import FieldWrapper, { FieldWrapperPassThroughProps } from './FieldWrapper'

import {UseFormRegisterReturn} from "react-hook-form"


import {AsyncSelect} from "chakra-react-select"

type InputFieldProps = FieldWrapperPassThroughProps  & {
    registration : Partial<UseFormRegisterReturn> , 
    inputStyle?: any , 
    loadOptions : any  , 
    name :string , 
    optionValue: string , 
    optionLabel: string , 
    setValue : any
}
  

const SelectAsyncFieldCompoent = (
   {
    registration , 
    error , 
    label  , 
    inputStyle={} ,
    name , 
    setValue , 
    loadOptions   ,
    optionValue , 
    optionLabel
   }:InputFieldProps

) => {
    const   loadOptionsLauncher = async  (
        search  : string 
    )=>{
        return new Promise<any[]>((resolve) => {
            setTimeout(() => {
              resolve(loadOptions(search));
            }, 1000);
        });
    }


    const handleChanges = (value:any)=>{
      setValue(name,  value)
        

    }
  
  

  return (
    <FieldWrapper
      error={error}
      label={label}
    >
        <AsyncSelect 
        // colorScheme='primary.500'
            // errorBorderColor="red.500" isInvalid
            {...registration}
            onChange={handleChanges}
            cacheOptions
            defaultOptions
            loadOptions={loadOptionsLauncher}
            focusBorderColor='primary.500'
            options={[{
                label : "batat" , 
                value : "1"
            }]}
            getOptionValue={(cell:any)=>cell[optionValue]}
            getOptionLabel={(cell:any)=>cell[optionLabel]}

        />
        {/* <Input 
          {...registration}
          {...inputStyle}
        /> */}
    </FieldWrapper>
  )
}


export default SelectAsyncFieldCompoent