

import ActivitiesList from "../components/ActivitiesList"



const ListActivities =() => {

  return (
    <>
      <ActivitiesList />
    </>
  )
}


export default ListActivities