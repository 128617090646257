import React from 'react'
import PropTypes from 'prop-types'

const UserIcon = (props: any) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 26 32.5">
  <path id="Path_6" data-name="Path 6" d="M25.1,18.4A3.9,3.9,0,0,1,29,22.3v1.3C29,28.726,24.165,34,16,34S3,28.726,3,23.6V22.3a3.9,3.9,0,0,1,3.9-3.9ZM16,1.5A7.15,7.15,0,1,1,8.85,8.65,7.15,7.15,0,0,1,16,1.5Z" transform="translate(-3 -1.5)" />
</svg>

  )
}

UserIcon.propTypes = {}

export default UserIcon