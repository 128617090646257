import {useState , useEffect} from "react"
import Card from "components/card/Card"
import TableComponent from "components/table/Table"
// import { extractUsers } from "../utils/extractdata"
import {userColumns } from "../variable/table"
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { useActivities } from "../api/getActivities"
import { Avatar, Box, Circle, Flex, Heading, Icon, IconButton, Image, LinkBox, LinkOverlay, Stack, Text, useColorModeValue } from "@chakra-ui/react"
import { Activity } from "../type"


import { BiPencil } from "react-icons/bi"
import OutlinePlusIcon from "assets/svgs/OutlinePlusIcon"
import { FormattedMessage } from "react-intl"

const limit  =  5 ; 

const ActivitiesList =()=> {
  const navigate = useNavigate()
  const location = useLocation()
  const  {super_activity_id} =  useParams()
  const [pageIndex , setPageIndex] = useState<number>(1)
  const [search , setSearch] = useState("")
  const [pageCount , setPageCount] = useState(1)
  const {data : activities , refetch : refetchActivities}  = useActivities({
    query  : {
      super_activity_id : {
        is : super_activity_id ?  super_activity_id : "null"
      }
    }
  })


  useEffect(()=>{
    refetchActivities()
  } , [location])


  useEffect(()=>{
    console.log("our activities are ", super_activity_id)
  } , [super_activity_id])



  const brandBg =  useColorModeValue("primary.500" , "primary.600")



  // useEffect(()=>{
  //   const count  = users?.data?.count ?? 1
  //   setPageCount(Math.ceil(count/limit))
  // } , [users])

  // useEffect(()=>{
  //   users.refetch()
  // } , [pageIndex ,search])


  // const navigateUserDetails = (row:User)=>{
  //   navigate(`/users/${row.id}`)

  // }


  return (
    <Stack>
      <Flex
        gap={5}
        flexWrap="wrap"
      >
        {
          activities && activities.map((activity:Activity)=>{
            return(
              <Stack
                alignItems={"center"}
                position="relative"
              >
                  <LinkBox
                     position={'absolute'}
                     right={"-10px"}
                     top={"-10px"}
                     opacity="0"
                     _hover={{
                      opacity: 1
                     }}
                  >
                    <Link  to={`/activities${super_activity_id
                  ?`/${super_activity_id}` : ""}/${activity?.activity_id}`} >
                      <LinkOverlay>
                        <IconButton 
                          aria-label="edit-activitie"
                          icon={<BiPencil />}
                          fontSize={"xl"}
                          variant="primaryOutline"
                          borderRadius={"full"}
                        />
                      
                      </LinkOverlay>
                    </Link>
                  
                  </LinkBox>
                <LinkBox>
                  <Link
                    to={super_activity_id ? ""  :`/activities/${activity?.activity_id}/activities`}
                  
               
                  >
                    <Circle
                        bg={brandBg}
                        p={5}
                      
                      >
                        <Avatar 
                          src={activity?.activityPic?.file_url}
                          name={activity?.activity_name}
                          color={"white"}
                          bg={brandBg}
                          size={"4xl"}
                          h={"100px"}
                          w={"100px"}
                        />

                      </Circle>

                  </Link>
                  </LinkBox>
                
                <Text
                  fontSize={"md"}
                  fontWeight={500}
                  textTransform="capitalize"
                >
                  {activity?.activity_name}
                </Text>
              </Stack>
            )
          })
        }

        <Stack
          alignItems={"center"}
        >
          <LinkBox>
            <Link
              to={super_activity_id ?`/activities/${super_activity_id}/new` :  `/activities/new`  }
            >
                <Circle
                  bg={brandBg}
                  p={5}
                
                >
                  <Icon 
                    h={"100px"}
                    w={"100px"}
                    fill="white"
                    as={OutlinePlusIcon}
                  />

                </Circle>
            </Link>
          
          </LinkBox>
          <Text
            fontSize={"md"}
            fontWeight={500}
            textTransform="capitalize"
          >
            <FormattedMessage id="new" />
          </Text>

        </Stack>
      
      </Flex>
      

    </Stack>
  )
}


export default ActivitiesList