

import  {useQuery, UseQueryResult} from "react-query"
import { axios } from "lib/axios"



export const getAds = async  (query:any={}) :Promise<any[]> =>{
    
    const response = await   axios.get(
        "/ad",
        {
            params :{
                ...query 
            }
        }
    )

    const data  =  response.data
    return data?.ads


}




type  useAdsOptions = {
    config? :any  , 
    query?:any
}


export const  useAds :({config , query}:useAdsOptions)=>UseQueryResult<any[], Error> = ({config , query} = {})=>{
    
    
    return (
        useQuery(
            ["Ads"] , 
            ()=>getAds(query) , 
            config
        )
    )

}

