
import { AuthRoutes } from "features/auth/routes";



export const publicRoutes =[
    // {
    //     path : "/reparations/*",
    //     element: <ReparationRoutes />
    // } , 
    {
        path : "/auth/*",
        element : <AuthRoutes />
    } 

]