import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Text } from '@chakra-ui/react'
import { useOrders } from '../api/getOrders'
import { extractOrders } from '../utils/extractData'
import Card from 'components/card/Card'
import TableComponent from 'components/table/Table'
import { Order } from '../types'
import { useNavigate } from 'react-router-dom'
import { ordersColumns } from '../variables/table'

const OrdersList = () => {
    const navigate = useNavigate()
    const [pageIndex, setPageIndex ] =  useState<number>(1);



    const { data: orders } = useOrders({})

    const navigateOrderDetails = (order:any) =>{
        navigate(`/orders/${order?.id}`)

    }

    const setSearch  =  ()=>{
        
    }

    return (
        <Card>
            <TableComponent<Order>
                name="stores"
                selectRow={navigateOrderDetails}
                data={extractOrders(orders?.orders ??[])}
                tableColumns={ordersColumns}
                setPageIndex={setPageIndex}
                pageIndex={pageIndex}
                pageCount={orders?.count ?? 0}
                searching={setSearch}
                detailsIcon={true}
            />
        </Card>
    )
}


export default OrdersList