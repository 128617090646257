import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import z from "zod"
import { Avatar, Box, Button, Editable, EditableInput, EditablePreview, Flex, HStack, LinkBox, LinkOverlay, Stack, Text, useColorModeValue } from '@chakra-ui/react'

import Form from 'components/form/Form'
import ActivityPhoto from './ActivityPhoto'

import {FcGoogle} from "react-icons/fc"
import { HSeparator } from 'components/separator/Seperator'
import { Link, useParams } from 'react-router-dom'
import InputField from 'components/form/InputField'
import NumberInputFieldComponent from 'components/form/NumberInputField'
import { useAuth } from 'lib/auth'
import Card from 'components/card/Card'
import { FormRequestBuilder } from '../utils/formRequestBuilder'
import { useCreateActivity } from '../api/createActivity'
import { useActivity } from '../api/getActivity'
import {  useUpdateActivity } from '../api/updateActivity'
import SwitchField from 'components/form/SwitchField'
import If from 'common/If'



const schema = z.object({
    activity_name: z.string() ,
    file : z.any(),
    activity_has_category: z.any()
    // password: z.string().min(8, 'password greater then 8'),
    // phoneNumber : z.string().regex(
    //     new RegExp("^(05|06|07)") , "phone number start with 05, 06 , 07"
    // ) , 
    // displayName : z.string()
    // min(8  , "password 8 characeres ").max(8 ,"pass should 8 characters" )
});


type IProfileForm  = {
    activity_name : string , 
    activity_has_category: boolean;
    file : any , 
}

type ProfileFormProps = {
    onSuccess : any ,
    title?: string
}

const ActivityForm = (
    {
        onSuccess  , 
        title =""
    }:ProfileFormProps

) => {
    const {activity_id , super_activity_id} =  useParams()
    const {data  : activity} =  useActivity({
        query  :{
            activity_id 
        }
    })
    const {mutate : createActivity , isLoading:isCreating , isSuccess : isCreatingSuccess} =  useCreateActivity()
    const {mutate : updateActivity , isLoading:isUpdating , isSuccess : isUpdatingSuccess} =  useUpdateActivity()
    // const {data:activity , refetch} =  useActivity({
    //     params : {activity_id}}
    // ) 




    useEffect(()=>{
        if(isCreatingSuccess || isUpdatingSuccess){
            onSuccess()
        }
    } , [isCreatingSuccess ,isUpdatingSuccess ])



    const handleActivtySubmit =  (data:any)=> {
        

        const activityForm = new FormRequestBuilder(
            data
        ).getFormData()


        

        if(activity_id){
            updateActivity(
                {
                    data  : activityForm , 
                    activity_id ,                  
                    super_activity_id
                }
            )
        }else{
            createActivity(
                {
                    data : activityForm  , 
                    super_activity_id
                }
            );
        }

    }


   
    const headerBg =  useColorModeValue("primary.500" , "primary.700")
    const brandColor =  useColorModeValue("primary.500" , "white")



    

    return (
        <Flex
            gap={10}
            justifyContent="space-evenly"
        >
          
            <Box>
                <If condition={activity} >
                <Form<
                    IProfileForm ,
                    typeof schema 
                >
                    schema={schema}
                    onSubmit={handleActivtySubmit}
                >
                    {
                        ({register , formState})=>(
                            <Card
                                position={"relative"}
                                py={15}
                                pt={120}
                                overflow="hidden"
                            >
                                <Flex
                                    bg={headerBg}
                                    position="absolute"
                                    top={0}
                                    left={0}
                                    w={"100%"}
                                    h={70}
                                    px={5}
                                    alignItems="center"

                                >
                                    <LinkBox>
                                        <Link
                                            to={`/activities/${activity?.activity_id}/activities`}
                                        >
                                            <Text
                                                color={"white"}
                                                fontSize="lg"
                                                textTransform={"capitalize"}
                                                fontWeight={500}
                                            >
                                                {activity_id ? activity?.activity_name : "nouvelle activitie"} 
                                            </Text>
                                        </Link>
                                    </LinkBox>
                                </Flex>
                                <Flex
                                    flexWrap="wrap"
                                    columnGap={5}
                                    justifyContent="space-evenly"
                                >
                                    <ActivityPhoto 
                                        photo={activity?.activityPic} 
                                        name={""}
                                        registration={register('file')}
                                    />
                                    <Box>
                                        <Box
                                            mb={6}
                                        >
                                            <InputField 
                                                registration={register('activity_name')}
                                                error={formState.errors['activity_name']}     
                                                label={"nom"}
                                                defaultValue={activity_id ? activity?.activity_name : ""}
                                                inputStyle={{
                                                    variant : "auth" , 
                                                    fontSize : "sm" , 
                                                    size : "lg",
                                                    fontWeight : 500
                                                }}                          
                                            />

                                        </Box>

                                        <Box
                                            mb={6}
                                        >
                                            <SwitchField 
                                                registration={register('activity_has_category')}
                                                error={formState.errors['activity_has_category']}     
                                                label={"A categorie"}
                                                defaultValue={activity_id ? activity?.activity_has_category : false}
                                                inputStyle={{
                                                    variant : "auth" ,
                                                    colorScheme: 'green' ,
                                                    fontSize : "sm" , 
                                                    size : "lg",
                                                    fontWeight : 500
                                                }}                          
                                            />

                                        </Box>
                                       


                                    </Box>
                                    <Flex
                                        w={"100%"}
                                        justifyContent="space-evenly"
                                        my={5}
                                    >
                                        <Button
                                            variant="primaryFill"
                                            type="submit"
                                            isLoading={isCreating}
                                            fontSize={"sm"}
                                            w={150}
                                        >
                                            Sauvgarder

                                        </Button>

                                    </Flex>
                                </Flex>

                            </Card>
                            
                        )
                    }
                </Form>
                </If>
            </Box> 

        </Flex>
    )
}


export default ActivityForm