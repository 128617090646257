import { Socket_URL } from 'config';
import { SocketContext } from 'contexts/socketContext';
import React, { PropsWithChildren, useEffect, useState } from 'react'
import io from 'socket.io-client';
import storage from 'utils/storage';

// const socket = io("https://api.swiftdeliverydz.com/" , {
//     extraHeaders:{
//         Authorization: `Bearer ${storage.getToken()}`
//     }
// });

const socket = io(Socket_URL , {
    extraHeaders:{
        Authorization: `Bearer ${storage.getToken()}`
    }
});

const SocketProvider = ({children}: PropsWithChildren<any>) => {

    const [isConnected, setIsConnected] = useState(socket.connected);
    const [lastPong, setLastPong] = useState(null);
  
    useEffect(() => {
      console.log("here we go");
      console.log("socket => ", socket)
      socket.on('connect', () => {
        console.log("connecting")
        setIsConnected(true);
      });
  
      socket.on('disconnect', () => {
        console.log("disconnected")
        setIsConnected(false);
      });
      return () => {
        console.log("refetch")
        socket.off('connect');
        socket.off('disconnect');
        socket.off('pong');
      };
    }, [isConnected, setIsConnected]);
  



  return (
    <SocketContext.Provider value={{socket}}>
        <div>{children}</div>
    </SocketContext.Provider>
  )
}


export default SocketProvider