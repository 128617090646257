import { Route, Routes } from "react-router-dom"
import ActivityDetails from "../pages/ActivityDetails"
import ListActivities from "../pages/listActivities"
import NewActivity from "../pages/NewActivity"



export const ActivityRoutes = ()=>{
    return(
        <Routes>
            <Route  path="/" element={<ListActivities />} />
            <Route  path="/new" element={<NewActivity />} />
            <Route path="/:super_activity_id/activities" element={<ListActivities/>} />
            <Route path="/:super_activity_id/new" element={<NewActivity/>} />
            <Route  path="/:activity_id"  element={<ActivityDetails />} /> 
            <Route path="/:super_activity_id/:activity_id" element={<ActivityDetails/>} />
        </Routes>
    )
}