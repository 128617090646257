

import  {useQuery, UseQueryResult} from "react-query"
import { axios } from "lib/axios"
import { ExtractFnReturnType , QueryConfig } from "lib/react-query"

type getPaymentStatsQueryType = {
    from?: any , 
    to?: any,
    stepUnit?: string,
    stepValue?: number
}


export const getPaymentStats = async  (query : getPaymentStatsQueryType ={}) :Promise<any> =>{
    console.log("our query is  ", query)
    
    const response = await   axios.get(
        "/admin/stats/payment",
        {
            params : {
                ...query
            }
        }
    )


    const data  =  response.data
    return data


}


type FnType  =  typeof getPaymentStats;





type  usePaymentStatsOptions = {
    config? :any , 
    query?: getPaymentStatsQueryType
}


export const  usePaymentStats :({config , query}:usePaymentStatsOptions)=>UseQueryResult<{payments:number[] , delivery: number[]}, Error> = ({config , query} = {})=>{
    return (
        useQuery(
            ["/admin/stats/payment"] , 
            ()=>getPaymentStats(query) , 
            config
        )
    )

}

