import { Deliver } from "features/delivers/type"
import { DeliveryPriceSetting } from "../type"




export const  extractDeliveryPriceSetting : (deliveryPriceSettings : any)=> { first: DeliveryPriceSetting[],  second: DeliveryPriceSetting[] | null }  =  (deliveryPriceSettings :  any) =>{
    
    
    let snapDeliverySettings: DeliveryPriceSetting[] = deliveryPriceSettings?.map((deliveryPriceSetting: DeliveryPriceSetting)=>{
        return(
            {
                radius: deliveryPriceSetting?.radius, 
                unity: deliveryPriceSetting?.unity,
                priority: deliveryPriceSetting?.priority,
                price: deliveryPriceSetting?.price,
                region_name: deliveryPriceSetting?.region_name
    
            }
        )

    })

    snapDeliverySettings.sort((a:DeliveryPriceSetting , b:DeliveryPriceSetting)=>{
        if(a.priority > b.priority){
            return 1
        }
        return -1;
    })


    const  firsSnapDeliverySettings: DeliveryPriceSetting[]  = [] ; 
    const  secondSnapDeliverySettings: DeliveryPriceSetting[] | null = []  ; 


    snapDeliverySettings.forEach((deliverySetting: DeliveryPriceSetting , key: number) => {
        if(key< snapDeliverySettings.length / 2 ){
            firsSnapDeliverySettings.push(deliverySetting);
        }else{
            secondSnapDeliverySettings.push(deliverySetting);
        }
    });


    return {
        first : firsSnapDeliverySettings,
        second: secondSnapDeliverySettings.length > 0  ? secondSnapDeliverySettings : null
    };

    






   

} 
