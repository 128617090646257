
// id : number ,
// clientName: string,
// storeName: string,
// orderNumber: number
export const ordersColumns = [
 
    {
        Header :"client name",
        accessor : "client_name",
    }, 
    {
        Header :"store name",
        accessor : "store_name",
    }, 
    {
        Header :"store number",
        accessor : "order_number",
    }, 
    {
        Header: "deliver",
        Type : "deliverCell",
        accessor : "deliver"
    },  
    {
        Header: "status",
        Type : "orderStatus",
        accessor : "order_status"
    }
    

]