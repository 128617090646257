


import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    Autocomplete,
    DirectionsRenderer,
    Polyline,
    LoadScript
} from "@react-google-maps/api";


import {  Box, useDisclosure } from '@chakra-ui/react'


//icons
import Scootter from "assets/scooter.svg";
import Restaurant from "assets/restaurant.svg";
import Client from "assets/client.svg"
import Transparent from "assets/transparent.svg";

//contexts
import { useOrderContext } from 'contexts/orderContext';
import { useSocketContext } from 'contexts/socketContext';
import { useEffect, useRef, useState } from "react";
import If from "common/If";
import { useUpdateOrderDeliver } from "../api/updateOrderDeliver";


const libraries = ["drawing",'places']
const markerTypes: any = {
    deliver: Scootter,
    client: Client,
    store: Restaurant
}


const TestMap = () =>{
    const  {socket } =  useSocketContext();
    const { order } =  useOrderContext();
    const polylineRef = useRef<any>(null)

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAGtikbazdLPkXi4sm-ZbKq1QX6xtrdyOk",
        libraries,
    });




    const [coords , setCoords] =  useState<any>([])
    const [currentPosition , setCurrentPosition ] = useState<any>({})
    const [map , setMap ]  = useState<any>(null)
    const [originType, setOriginType ] = useState<string>("deliver")
    const [destinationType, setDestinationType ] = useState<string>("store")
    const [deliverAddress, setDeliverAdress] =  useState<any>(null);
    const [clientAddress, setClientAddress ] = useState<any>(null);
    const [storeAddress, setStoreAddress ] =  useState<any>(null)
    const [directions , setDirections] = useState<any>([]);
    const [origin, setOrigin]=  useState<any>(null);
    const [destination, setDestination ] = useState<any>(null)




      
    const mapDirectionInitialiser = async (origin:any , destination:any) =>{
        const directionsService = new google.maps.DirectionsService();
        const result = await directionsService.route({
            origin,
            destination,
            travelMode: google.maps.TravelMode.DRIVING,
        })
        // new google.maps.DirectionsRenderer({ suppressMarkers: true, polylineOptions: { strokeColor: '#5cb85c' } });

        // console.log("our result is ", result);

        setDirections([result]);

        const res = result.routes[0].overview_path;


        setOrigin({
            lat: res[0].lat(),
            lng: res[0].lng()
        })

        setDestination({
            lat: res[res.length - 1].lat(),
            lng: res[res.length - 1].lng() ,
        })

        
        setCoords([[...coords, res]]);
    }

    
    

    const onMapLoad = (map: any) => {
        navigator?.geolocation.getCurrentPosition(
          ({ coords: { latitude: lat, longitude: lng } }) => {
            const pos = { lat, lng };
            setCurrentPosition(pos)
        })

        setMap(map)
        // mapDirectionInitialiser(origin)
        // google.maps.event.addListener(map, "bounds_changed", () => {
        //   console.log(map.getBounds());
        //   this.setState({ bounds: map.getBounds() });
        // });
    };

    useEffect( ()=>{

        console.log("order  => ", order)
        if(order){
            if(order?.storeAddress){
                setStoreAddress({
                    lat: order?.storeAddress?.lat,
                    lng: order?.storeAddress?.lng
                })
            }

            if(order?.clientAddress){
                setClientAddress({
                    lat: order?.clientAddress?.lat,
                    lng: order?.clientAddress?.lng
                })
            }


            if(order?.deliverAddress){
                setDeliverAdress({
                    lat: order?.deliverAddress?.lat,
                    lng: order?.deliverAddress?.lng
                })
            }


                

                
    
            
            
            if(socket){
                socket.on("admin:deliver-move" , (message: any)=>{
                    if(message?.deliver_id ===  order?.deliver?.deliver_id){
                        if(message?.lat && message?.lng){
                            setDeliverAdress({
                                lat: message?.lat, 
                                lng: message?.lng
                            });
                        }
                    }
                })
            }
        }


    }, [order , socket])


    useEffect( ()=>{
        if(isLoaded){
            if(order?.order_status === "assigned" || order?.order_status === "on_way"){
                setOriginType("deliver");
                setDestinationType("store")
                mapDirectionInitialiser(deliverAddress , storeAddress);
            }

            if(order?.order_status === "taken"){
                setOriginType("store");
                setDestinationType("client")
                mapDirectionInitialiser(storeAddress , clientAddress);
            }
        }
    }, [isLoaded ,clientAddress , storeAddress , deliverAddress , order])





    if(!isLoaded){
        return (
            <div>
                loading
            </div>
        )
    }

    return (
        <Box
            position={"relative"}
            h="100%"
            w="100%"
        >
            <GoogleMap
                center={order?.storeAddress}
                zoom={10}
                mapContainerStyle={{ width: '100%', height: '100%' }}
                onLoad={onMapLoad}
                options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                
                }}
                // onClick={(e: any)=>{
                //     console.log("our e is ", e.latLng.lat() , e.latLng.lng()  )
                // }}
            >
                {
                    coords?.map((coord: any, key: any)=>{
                        return(
                            <Polyline
                            key={key}
                                ref={polylineRef}
                                path={coord[coord?.length -1]}
                                geodesic={true}
                                options={{                               
                                    strokeColor: order?.order_status==="on_way" ? "#63FD52":  "#FC7700",
                                    strokeOpacity: 0.8,
                                    strokeWeight: 8,
                                    clickable: true
                                }}
                            />

                        )
                    })
                }
                <If condition={origin}>
                    <Marker
                        position={origin}
                        icon={markerTypes[originType]}
                    /> 
                </If>
                <If condition={order?.order_status==="taken"}>
                    <Marker
                        position={deliverAddress}
                        icon={Scootter}
                    /> 
                </If>
                <If condition={destination}>
                    <Marker
                        position={destination}
                        icon={markerTypes[destinationType]}
                    /> 

                </If>
                {
                    directions && directions?.map((direction:any , key: any)=>{
                        return(
                            <DirectionsRenderer 
                                key={key}
                                directions={directions} 
                                options={{
                                    // circleOptions: {
                                    //     fillColor: `#ffff00`,
                                    //     fillOpacity: 1,
                                    //     strokeWeight: 5,
                                    //     clickable: false,
                                    //     editable: true,
                                    //     zIndex: 1,
                                    // },
                                    polylineOptions: {
                                        stokeColor: "#C00",
                                        strokeOpacity: 0,
                                        strokeWeight: 8
                                    },
                                    // polyline: {
                                    //     strokeColor: '#C00',
                                    //     strokeOpacity: 0.7,
                                    //     strokeWeight: 20
                                    // },
                                    markerOptions: { 
                                        icon: Transparent 
                                    } 
                                        
    
                                }}
                            />

                        )
                    })
                }

            </GoogleMap>

            
        {/* <HStack  
//                 bg="white"
//                 position="absolute"
//                 top='10px'
//                 left="10px"
//                 w="300px"
//                 h="60px"
//                 borderRadius="md"
//             >
//                 <motion.div
//                     animate={{
//                         boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",
//                     }}
//                     transition={{ repeat: Infinity }}
                  
//                 >
//                     <Circle 
//                         size="8px"
//                         background="#63FD52"
//                         border-radius= "100%"
//                         boxShadow= {`0px 0px 20px 9px #63FD52`}
//                     />
            
//                 </motion.div>
//             </HStack> */}
        </Box>
    )
}


export default  TestMap;