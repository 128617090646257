import { Box } from "@chakra-ui/react"
import Navbar from "components/navbar/Navbar"
import Sidebar from "components/sidebar/Sidebar"
import { ReactNode } from "react"
import { menuItems } from "variables/menu-items"


type AdminLayoutProps = {
    children : ReactNode
}


const AdminLayout = (
    {
        children
    }:AdminLayoutProps
) => {
  return (
    <Box
    >
        <Sidebar 
            menuItems={menuItems}
        />
        <Box
            float={"right"}
            w={{base :"100%" , xl : "calc(100% - 300px )"}}
            maxW={{base :"100%" , xl : "calc(100% - 300px )"}}
            minHeight='100vh'
            height={"100%"}
            maxHeight="100%"

        >
            <Box>
                <Navbar />
            </Box>
            <Box
                mx='auto' 
                p={{ base: '20px', md: '30px' }} 
                pe='20px' 
                minH='100vh'
                mt='100px'
            >
                {
                    children
                }

            </Box>

        </Box>
    </Box>
  )
}

AdminLayout.propTypes = {}

export default AdminLayout