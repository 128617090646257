
import { Box } from "@chakra-ui/react"
import Card from "components/card/Card"
import TableComponent from "components/table/Table"
import { useDelivers } from "../api/getDelivers"
import { useEffect, useState } from "react"
import { Deliver } from "../type"
import { extractDelivers } from "../utils/extractData"
import { deliversColumns } from "../variables/table"
import { Store } from "features/store/type"
import { Navigate, useNavigate } from "react-router-dom"
import NewDeliverIcon from "../components/NewDeliverIcon"


type ListStoresProps = {
  store_activated?: boolean
}




const ListDelivers =() => {
    const navigate = useNavigate()
    const { data:delivers  } =  useDelivers({
        query: {

        }
    })
    const [pageIndex, setPageIndex ] =  useState<number>(1);



    const navigateDeliveryDetails = (deliver: Store) =>{
        navigate(`/delivers/${deliver?.id}`);
    }

    const setSearch  =  ()=>{
        
    }

    useEffect( ()=>{
        console.log("our delivers are ", extractDelivers(delivers?.delivers ??[]));
    }, [delivers])
    return (
        <Card>
            <TableComponent<Deliver>
                name="stores"
                selectRow={navigateDeliveryDetails}
                data={extractDelivers(delivers?.delivers ??[])}
                tableColumns={deliversColumns}
                setPageIndex={setPageIndex}
                pageIndex={pageIndex}
                pageCount={delivers?.count ?? 0}
                searching={setSearch}
                newIcon={<NewDeliverIcon />}
            />
        </Card>
    )
}


export default ListDelivers