import {useState , useEffect} from "react"
// import { extractUsers } from "../utils/extractdata"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Avatar, Flex,  LinkBox, Stack, Text, useColorModeValue } from "@chakra-ui/react"

import { useCategories } from "../api/getCategories"
import { Category } from "../type"

const limit  =  5 ; 

const CategoriesList =()=> {
  const  {store_id} =  useParams()
  const {data : categoriesData}  = useCategories({
    query  : {
      store_id:{
        eq: store_id,
      }
    }
  })




  const brandBg =  useColorModeValue("primary.500" , "primary.600")



  // useEffect(()=>{
  //   const count  = users?.data?.count ?? 1
  //   setPageCount(Math.ceil(count/limit))
  // } , [users])

  // useEffect(()=>{
  //   users.refetch()
  // } , [pageIndex ,search])


  // const navigateUserDetails = (row:User)=>{
  //   navigate(`/users/${row.id}`)

  // }


  return (
    <Stack>
      <Flex
        gap={5}
        flexWrap="wrap"
      >
        {
          categoriesData?.categories?.map((category:Category)=>{
            return(
              <Stack
                alignItems={"center"}
              >
                <LinkBox>
                  <Link
                    to={`${category?.id}`}
                  >
                    <Stack
                        bg={brandBg}
                        p={5}
                        borderRadius={"md"}  
                        w={170}
                        alignItems={"center"}
                      >
                        <Avatar 
                          src={category?.iconPicture?.file_url}
                          name={category?.category_name}
                          borderRadius={"0"}
                          color={"white"}
                          bg={brandBg}
                          size={"4xl"}
                          h={"100px"}
                          w={"100px"}
                        />
                         <Text
                          fontSize={"md"}
                          fontWeight={500}
                          textTransform="capitalize"
                          color={"white"}
                          mx="auto"
                          textAlign={"center"}
                          maxW={100}
                        >
                  {category?.category_name}
                </Text>

                      </Stack>

                  </Link>
                  </LinkBox>
                
               
              </Stack>
            )
          })
        }

        {/* <Stack
          alignItems={"center"}
        >
          <LinkBox>
            <Link
              to={super_activity_id ?`/activities/${super_activity_id}/new` :  `/activities/new`  }
            >
                <Circle
                  bg={brandBg}
                  p={5}
                
                >
                  <Icon 
                    h={"100px"}
                    w={"100px"}
                    as={AiOutlinePlus}
                    color="white"
                  />

                </Circle>
            </Link>
          
          </LinkBox>
          <Text
            fontSize={"md"}
            fontWeight={500}
            textTransform="capitalize"
          >
            {"new"}
          </Text>

        </Stack> */}
      
      </Flex>
      

    </Stack>
  )
}


export default CategoriesList