import { 
    Box, 
    Button, 
    Flex, 
    HStack, 
    Icon, 
    IconButton, 
    Modal, 
    ModalBody, 
    ModalContent, 
    ModalHeader, 
    ModalOverlay, 
    Stack, 
    Text, 
    Tfoot, 
    Thead,
    useColorModeValue,
    useDisclosure
} from "@chakra-ui/react"


  
import { useMemo } from "react"
import { 
    useTable  , 
    useSortBy , 
    usePagination,
    useGlobalFilter 
}  from "react-table"

  
import { Table, Tbody, Td, Th, Tr } from "@chakra-ui/react"
import Status from "./components/status"
import Image from "./components/image"
// import Card from "components/card/Card"
import SearchBar from "components/navbar/SearchBar"



import {AiOutlineFilter} from "react-icons/ai"
import UserType from "./components/userType"
import Cell from "./components/cell"
import Date from "./components/date"
// import Pagination from "components/pagination/Pagination"
import DeliverCell from "features/orders/components/DeliverCell"
import OrderStatus from "features/orders/components/OrderStatus"
import {BiShowAlt } from "react-icons/bi"
import If from "common/If"
import { defaultFn } from "utils/functions"
import { FormattedMessage } from "react-intl"
  


const components:any = {
  status : Status,
  image : Image , 
  userType : UserType , 
  cell : Cell, 
  date : Date,
  deliverCell: DeliverCell,
  orderStatus: OrderStatus
}



type ColumnType  =   {
  Header : string, 
  Type?: string,
  accessor : string
}

type TableComponentProps<Entry>={
  selectRow:any , 
  data : Entry[] ,
  name : string , 
  tableColumns : ColumnType[]  , 
  setPageIndex : any ,
  pageIndex : number , 
  pageCount : number , 
  searching?: any,
  detailsIcon?: boolean , 
  FilterForm?: any,
  newIcon?: any,
  refilter?: (filters: any)=> void

}
const TableComponent = <Entry extends {id : number} >(
    {
      selectRow , 
      data ,
      tableColumns , 
      setPageIndex,
      pageIndex ,
      pageCount,
      name , 
      newIcon=null,
      searching =()=>{},
      FilterForm = null,
      detailsIcon= false,
      refilter= defaultFn
    }:
    TableComponentProps<Entry>
  
  ) => {
    const columns:any = useMemo(()=>tableColumns , [tableColumns])
    const { isOpen: filterOpen, onClose: closeFilter , onOpen: openFilter } =  useDisclosure()
    const table:any =  useTable({  columns , data },
        // useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    )
    const {  
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,

      prepareRow , 
      initialState

    } = table

  
    initialState.pageSize = 5

  
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');


    const handleRefilter   =(filters: any) =>{
        closeFilter();
        refilter(filters)
    }


    return(
      <Stack>
        {
          FilterForm &&
          <FilterForm refilter={handleRefilter} onClose={closeFilter} isOpen={filterOpen} />
        }

      <Flex
            mb={5}
            align="center"
            justify={"space-between"}
          >
            <Text
              color={textColor}
              textTransform="capitalize"
              fontWeight={"semibold"}
              fontSize="3xl"
            >
              {
                name && <FormattedMessage id={name} />
              }
    
            </Text>
            <Flex
              alignItems={"center"}
              columnGap={4}
            >
              <If condition={newIcon}>
                {newIcon}
              </If>
              <IconButton 
                variant={"primaryFill"}
                aria-label="filter"
                borderRadius={"md"}
                icon={<AiOutlineFilter/>}
                onClick={openFilter}
              />
              {/* <Icon 
                as={AiOutlineFilter}
                bg='primary.500'
                color={"white"}
                fontSize='2xl'
              /> */}
              <SearchBar 
                borderRadius="xl"
                placeholder="search"
                searching={searching}
              />

            </Flex>
      </Flex>
        <Table    {...getTableProps()}>
          <Thead>
          {
              headerGroups.map(((headerGroup:any , index:any)=>{
                return(
                  <Tr
                  key={index}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {
                      headerGroup.headers.map((column:any,  index:any)=>{
                        return(
                          <Th
                          
                            p={5}
                            {
                              ...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )
                            }
                            key={index}
                            pe="10px"
                            borderColor={borderColor}
                          >
                            <Flex
                              justify='space-between'
                              align='center'
                              fontSize={{ sm: '10px', lg: "sm" }}
                              color='gray.400'
                              textTransform={"uppercase"}
                            >
                              {
                                column.render("Header")
                              }
                              {
                                column.canFilter &&
                                column.render("Filter")
                              }

                            </Flex>

                          </Th>

                        )

                      })
                    }
                    

                  </Tr>

                )

              }))
            }

          </Thead>

          <Tbody  {...getTableBodyProps()}>
            {
              page.map((row:any , index:any)=>{
                prepareRow(row)
                return(
                  <Tr
                    {...row.getRowProps()}
                    key={index}
                    cursor="pointer"
                    onClick={()=>{
                      if(!detailsIcon){
                        selectRow(row.original)
                      }
                    }}
                  >
                      {

                        row.cells.map((cell:any , index:any)=>{
                          // const data:any = 
                          // console.log("our dara ", cell)
                          
                          const column = columns[index]
                          const componentType  =  (column && column.Type) || "cell"

                          let Component = componentType ? components[componentType] : null
                          
                          return(
                            <Td
                              {...cell.getCellProps()}
                              key={index}
                              border="none"
                            >
                              <Text  
                                fontSize='sm' 
                                fontWeight='700'
                                color="gray.900"
                              >
                                {
                                  <Component 
                                  value={cell.value}
                                  />
                                }
                              </Text>
                            </Td>
                          )
                        })
                      }

                    <If condition={detailsIcon} >
                      <Td>
                        <IconButton 
                          onClick={()=>{selectRow(row.original)}}
                          aria-label="details"
                          size="sm"
                          variant={"primaryFill"}
                          borderRadius={"lg"}
                          icon={<BiShowAlt />}
                        />
                      </Td>


                    </If>


                    
                  </Tr>                  
                )
              })
            }

          </Tbody>
        </Table>

      </Stack>
    )
}
  


export default TableComponent