import { Route, Routes } from "react-router-dom"
import Login from "../pages/login"
import PhoneLogin from "../pages/PhoneLogin"


export const AuthRoutes = ()=>{
    return(
        <Routes>
            <Route  path="/login" element={<Login />} />
            {/* <Route  path="/login/phone" element={<PhoneLogin />} /> */}
        </Routes>
    )
}