
import ActivityIcon from "assets/svgs/ActivityIcon"
import CommandeIcon from "assets/svgs/CommandeIcon"
import DeliverIcon from "assets/svgs/DeliverIcon"
import PaymentIcon from "assets/svgs/PaymentIcon"
import StoreIcon from "assets/svgs/StoreIcon"
import UserIcon from "assets/svgs/UserIcon"
import {FaBuysellads, FaRegUser} from "react-icons/fa"
import {
    MdStorefront , MdOutlineDeliveryDining , 
    MdOutlineVolunteerActivism
} from "react-icons/md"
import { menuItemsType } from "types/routes"



export const menuItems:menuItemsType[] = [
    {
        type  : "title" ,
        name : "users"
    },
    {
        type  : "path" ,
        name : "users",
        path : "/users",
        icon :UserIcon ,
        activeKey : "/users",
    },
    {
        type  : "title" ,
        name : "stores"
    },
    {
        type  : "path" ,
        name : "stores",
        path : "/stores",
        icon : StoreIcon  ,
        activeKey : "/stores",
        children : [
            {
                type  : "path" ,
                name : "demandes",
                path : "/stores/demands",
                activeKey :"/stores/demands"
            }
        ]
    },
    {
        type  : "path" ,
        name : "activities",
        path : "/activities",
        icon : ActivityIcon ,
        activeKey : "/activities",
    },
    {
        type  : "path" ,
        name: "delivers",
        path : "/delivers",
        icon : DeliverIcon,
        activeKey : "/delivers"
    },
    {
        type  : "title" ,
        name : "commandes"
    },
    {
        type  : "path" ,
        name: "commandes",
        path : "/orders",
        icon : CommandeIcon,
        activeKey : "/orders"
    },
    {
        type  : "title" ,
        name : "delivery"
    },
    {
        type  : "path" ,
        name: "revenueandprice",
        path : "/delivery",
        icon : PaymentIcon,
        activeKey : "/delivery"
    },
    {
        type  : "title" ,
        name : "ads"
    },

    {
        type  : "path" ,
        name: "ads",
        path : "/ads",
        icon : FaBuysellads,
        activeKey : "/ads"
    },


]


// export const routes:any =[]


