import React from 'react'
import PropTypes from 'prop-types'
import { menuItemsType } from 'types/routes'
import { Box, HStack, Icon, Text, useColorModeValue } from '@chakra-ui/react'
import SidebarContent from './SidebarContent'
import { AiOutlineLogout } from 'react-icons/ai'
import { FormattedMessage } from 'react-intl'
import { useAuth } from 'lib/auth'
import { Stack } from 'rsuite'


type SideBarProps = {
    menuItems : menuItemsType[]
}


const Sidebar = (
    {
        menuItems
    }:
    SideBarProps
) => {

  const { logout }   =  useAuth()

  let sideBarBg = useColorModeValue("white" , "dark.800")
  


  return (
    <Box 
      display={{sm :"none" , xl : "block"}}
      minH="100%"
      position={"fixed"}
      bg="rd"
    >
      <Box
        bg={sideBarBg}
        // h="100vh"
        w={300}
        justifyContent="space-between"
      >
        <SidebarContent 
          menuItems={menuItems}
        />

        <HStack mt="10px"  px="15px" cursor="pointer" onClick={() =>  { logout() } } >     

          <Icon   fontSize="xl" color="danger.500" >
            <AiOutlineLogout />
          </Icon>
          <Text 
                textTransform="uppercase"
                fontSize={"sm"}
                fontWeight="bold" > 
            <FormattedMessage id="logout" />
          </Text>
        </HStack>


      </Box>

  
    </Box>
  )
}

Sidebar.propTypes = {}

export default Sidebar