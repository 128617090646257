

import  {useQuery, UseQueryResult} from "react-query"
import { axios } from "lib/axios"
import { ExtractFnReturnType , QueryConfig } from "lib/react-query"
import { extractDeliveryPriceSetting } from "../utils/extractData"
import { DeliveryPriceSetting } from "../type"

type getDeliveryPriceSettingQueryType = {
}


export const getDeliveryPriceSettings = async  (query : getDeliveryPriceSettingQueryType ={}) :Promise<any> =>{
    const response = await   axios.get(
        "/delivery/priceSetting",
        {
            params : {
                ...query
            }
        }
    )

    const data  =  response.data
    return (
        extractDeliveryPriceSetting(data)
    )


}


type FnType  =  typeof getDeliveryPriceSettings;





type  useDeliveryPriceOptions = {
    config? :any , 
    query?: getDeliveryPriceSettingQueryType
}


export const  useDeliveryPriceSettings :({config , query}:useDeliveryPriceOptions)=>UseQueryResult<{ first : DeliveryPriceSetting[],second : DeliveryPriceSetting[] }, Error> = ({config , query} = {})=>{
    return (
        useQuery(
            ["delivery/price/settings"] , 
            ()=>getDeliveryPriceSettings(query) , 
            config
        )
    )

}

