import { useContext, createContext, useState } from "react";


export const StoresContext = createContext<{
  stores?: any,
  getStores?: any,
  setSearch: any,
  pageIndex?:any,
  setPageIndex: any,
}>({
    setSearch:()=>{},
    setPageIndex: ()=>{},
})

export const useStoresContext = () => useContext(StoresContext);
