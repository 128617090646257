import {useState , useEffect} from "react"
// import { extractUsers } from "../utils/extractdata"
import {  useNavigate, useParams } from "react-router-dom"
import { Avatar,  Flex,  Stack, Text, useColorModeValue } from "@chakra-ui/react"


import { useProducts } from "../api/getProducts"
import { Product } from "../type"

const limit  =  5 ; 

const ProductsList =()=> {
  const  {store_id} =  useParams()
  const [pageIndex , setPageIndex] = useState<number>(1)
  const {data : productsData}  = useProducts({
    query  : {
      product_store_id :{
        eq: store_id,
      }
    }
  })






  const brandBg =  useColorModeValue("primary.500" , "primary.600")



  // useEffect(()=>{
  //   const count  = users?.data?.count ?? 1
  //   setPageCount(Math.ceil(count/limit))
  // } , [users])

  // useEffect(()=>{
  //   users.refetch()
  // } , [pageIndex ,search])


  // const navigateUserDetails = (row:User)=>{
  //   navigate(`/users/${row.id}`)

  // }


  return (
    <Stack>
      <Flex
        gap={5}
        flexWrap="wrap"
      >
        {
          productsData?.products?.map((product:Product)=>{
            return(
                <Stack
                    borderRadius={"lg"} 
                    overflow={"hidden"} 
                    alignItems={"center"}
                    position={"relative"}
                    bg='red'
                    w="300px !important"
                    h="200"
                  >
                    <Avatar 
                      src={product?.productPicture?.file_url}
                      name={product?.product_name}
                      borderRadius={"lg"}
                      color={"white"}
                      bg={brandBg}
                      w="100%"
                      h="100%"

                      // size={"4xl"}
                      // h={"100"}
                      // w={"300"}
                    />
                    <Flex
                      position={"absolute"}
                      bottom={0}
                      left={0}
                      px={5}
                      py={3}
                      w="100%"
                      height="100%"
                      background="linear-gradient(0deg, #000000,transparent)"
                      alignItems= "end"
                      justifyContent= "flex-start"
                    >
                      <Text
                      fontSize={"md"}
                      fontWeight={500}
                      textTransform="capitalize"
                      color={"white"}
                      textAlign={"start"}
                      maxW={100}
                      
                    >
                    {product?.product_name}
                      </Text>

                    </Flex>

                </Stack>
            )
          })
        }

        {/* <Stack
          alignItems={"center"}
        >
          <LinkBox>
            <Link
              to={super_activity_id ?`/activities/${super_activity_id}/new` :  `/activities/new`  }
            >
                <Circle
                  bg={brandBg}
                  p={5}
                
                >
                  <Icon 
                    h={"100px"}
                    w={"100px"}
                    as={AiOutlinePlus}
                    color="white"
                  />

                </Circle>
            </Link>
          
          </LinkBox>
          <Text
            fontSize={"md"}
            fontWeight={500}
            textTransform="capitalize"
          >
            {"new"}
          </Text>

        </Stack> */}
      
      </Flex>
      

    </Stack>
  )
}


export default ProductsList