

import  {useQuery, UseQueryResult} from "react-query"
import { axios } from "lib/axios"
import { extractProducts } from "../utils/extractdata"
import { Product } from "../type"

type getUsersQueryType = {
    limit ?: number , 
    page ?: number , 
    search ?: string
}


export const getProducts = async  (query:any={}) :Promise<ProductsResponse> =>{
    
    const response = await   axios.get(
        "/admin/store/product",
        {
            params :{
                ...query 
            }
        }
    )

    const {products , ...productData}  =  response.data
    return (
        {
            ...productData ,
            products: extractProducts(products ?? [])

        }
    )


}


type FnType  =  typeof getProducts;





type  useProductsOptions = {
    config? :any  , 
    query?:any
}



type ProductsResponse  = {
    count?: number,
    products: Product[]
}


export const  useProducts :({config , query}:useProductsOptions)=>UseQueryResult<ProductsResponse, Error> = ({config , query} = {})=>{
    
    
    return (
        useQuery(
            ["products"] , 
            ()=>getProducts(query) , 
            config
        )
    )

}

