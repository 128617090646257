
import { Order } from "features/orders/types";
import { useContext, createContext, useState } from "react";


export const OrderContext = createContext<{
  order?:  Order,
}>({
})


export const useOrderContext = () => useContext(OrderContext);
