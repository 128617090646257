import { extendTheme, HTMLChakraProps, ThemingProps } from '@chakra-ui/react';
import { CardComponent } from './additions/card';
import { buttonStyles } from './components/button';
import { inputStyles } from './components/input';
import { globalStyles } from './styles';

export default extendTheme(
    globalStyles,
    inputStyles,
    CardComponent , 
    buttonStyles

);

export interface CustomCardProps extends HTMLChakraProps<'div'>, ThemingProps {}

