
import ActivityForm from "../components/ActivityForm"
import { useNavigate, useParams } from 'react-router-dom'

const NewActivity = () => {
    const navigate  =  useNavigate()
    const {activity_id , super_activity_id} =  useParams()
    return (
      <>
          <ActivityForm 
              onSuccess={()=>{navigate(
                `/activities${
                  super_activity_id ?
                  `/${super_activity_id}/activities` 
                  : ""
                }`)}}
              title={"nouvelle activitie"}
          />
      </>
      
    )
}

NewActivity.propTypes = {}

export default NewActivity