//events

export const ASSIGN_DELIVER = "assign-deliver"



//mesages

export const ORDER_ASSIGNED =  "admin:order-assigned"

