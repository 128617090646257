import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Form from 'components/form/Form'
import { Box, Button, Flex, LinkBox, LinkOverlay, Text, useColorModeValue } from '@chakra-ui/react'
import InputField from 'components/form/InputField'
import z from "zod"


import {FaPhoneAlt} from "react-icons/fa"
import { HSeparator } from 'components/separator/Seperator'
import { Link } from 'react-router-dom'
import { useAuth } from 'lib/auth'
import PhoneNumberInputField from 'components/form/PhoneNumberField'



const schema = z.object({
    auth_account: z.string().min(8 , "must be greater than 8") ,
    password: z.string().min(8, 'password must be  greater then 8'),
});


type ILoginForm  = {
    auth_account : string , 
    password : string
}

type LoginFormProps = {
    onSuccess : any
}

const LoginForm = (
    {
        onSuccess
    }:LoginFormProps

) => {
    const googleBg = useColorModeValue("secondaryGray.400" , "WhiteAlpha.200")
    const googleText  =  useColorModeValue("black.700" , "white")
    const googleHover =  useColorModeValue("gray.200" , "whiteAlpha.300")
    const googleBorder = useColorModeValue("gray.200" , "whiteAlpha.300")
    const {login , isLoggingIn , user } = useAuth()
    const dataUser = useAuth()


    const handleLogin  =  async (data:ILoginForm)=>{
        await dataUser.login( {
            ...data 
        })
        onSuccess();
    }


    



    return (
        <Box>
            <LinkBox>
                <Link
                    to='/auth/login/phone'
                >
                    <LinkOverlay>
                        <Button
                            leftIcon={<FaPhoneAlt />}
                            fontSize='sm'
                            fontWeight={500}
                            h='50px'
                            w="100%"
                            mb={10}
                            border={"1px solid"}
                            borderColor={googleBorder}
                            bg={googleBg}
                            color={googleText}
                            _hover={{
                                bg : googleHover
                            }}
                        >
                            login with phone number
                        </Button>
                    
                    </LinkOverlay>
                </Link>
            </LinkBox>
            
            
            <Form<ILoginForm ,typeof schema>
                schema={schema}
                onSubmit={handleLogin}
            >
            
                {
                    ({register , formState })=>(
                        <>
                            <Box
                                mb={12}
                            >
                                <InputField 
                                    registration={register('auth_account')}
                                    error={formState.errors['auth_account']}     
                                    label={"user name"}
                                    inputStyle={{
                                        variant : "auth" , 
                                        fontSize : "sm" , 
                                        size : "lg",
                                        fontWeight : 500
                                    }}                          
                                />

                            </Box>
                            <Box
                                mb={12}
                            >
                                <InputField 
                                    registration={register(
                                        'password' , 
                                        {
                                            required : true
                                        }
                                    )}
                                    label={"Password"}
                                    error={formState.errors['password']}
                                    type={"password"}
                                    inputStyle={{
                                        variant : "auth" , 
                                        fontSize : "sm" , 
                                        size : "lg",
                                        fontWeight : 500,
                                    }}
                                />
                            </Box>
                            <Button
                                type='submit'
                                variant={'primaryFill'}
                                w="100%"
                                h={"50px"}
                                fontSize="sm"
                                mb='24px'
                                isLoading={isLoggingIn}
                            >
                                Sign In
                            </Button>
                        </>
                        
                    )
                }
            </Form>
            
        </Box>
    )
}

LoginForm.propTypes = {}

export default LoginForm