import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, LinkBox, LinkOverlay } from '@chakra-ui/react'

import {AiOutlinePlus } from "react-icons/ai"
import { Link } from 'react-router-dom'

const NewDeliverIcon = () => {
  return (
    <LinkBox>
        <Link to="/delivers/new">
            <LinkOverlay>
                <IconButton 
                    variant={"primaryOutline"}
                    aria-label="new"
                    borderRadius={"md"}
                    icon={<AiOutlinePlus/>}
                />  
            </LinkOverlay>
        </Link>
    </LinkBox>
  )
}

export default NewDeliverIcon