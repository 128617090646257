import { Box, Button, Flex, Stack,useColorModeValue } from '@chakra-ui/react'
import { useDeliverContext } from 'contexts/deliverContext'
import InputField from 'components/form/InputField'
import DeliverPhoto from './DeliverPhoto'
import { z } from 'zod'
import Form from 'components/form/Form'
import { FormRequestBuilder } from 'features/activity/utils/formRequestBuilder'
import PhoneNumberInputField from 'components/form/PhoneNumberField'
import DropDownField from 'components/form/dropdownField'


const schema = z.object({
  user_firstname: z.string() ,
  user_lastname: z.string()  , 
  file : z.any(),
  phoneNumber: z.any(),
  password: z.any(),
  payment_type: z.any(),
  payment_tax: z.any(),
  payment_method: z.any(),
  // password: z.string().min(8, 'password greater then 8'),
  // phoneNumber : z.string().regex(
  //     new RegExp("^(05|06|07)") , "phone number start with 05, 06 , 07"
  // ) , 
  // displayName : z.string()
  // min(8  , "password 8 characeres ").max(8 ,"pass should 8 characters" )
});


type IProfileForm  = {
  user_firstname : string , 
  user_lastname: string,
  phoneNumber: string;
  password: string;
  payment_type: string;
  payment_tax: string;
  payment_method: string;
  // password : string , 
  // phoneNumber : number , 
  // displayName : string , 
  file : any , 
}



const DeliverForm = () => {
  const { deliver, updateDeliver, isUpdatingDeliver } = useDeliverContext();
  
  
  const headerBg =  useColorModeValue("primary.500" , "primary.700")

  const handleDeliverUpdate = (data: any)=> {

    const deliverForm = new FormRequestBuilder(data).getFormData()

    updateDeliver(deliverForm);

  }
  

  return (
    <Box>
    <Form<
        IProfileForm ,
        typeof schema 
    >
        schema={schema}
        onSubmit={handleDeliverUpdate}
    >
        {
            ({register , formState , setValue , getValues })=>(
              <Flex
                  // flexWrap="wrap"
                  columnGap={5}
                  justifyContent="space-evenly"
              >
                  <DeliverPhoto 
                      photo={deliver?.deliverPicture} 
                      name={""}
                      registration={register('file')}
                  />
                  <Stack>
                    <Flex
                      columnGap={5}
                      flexWrap={"wrap"}
                      justifyContent={"space-evenly"}
                    >
                        <Box
                            mb={6}
                            w="40%"
                        >
                            <InputField 
                                registration={register('user_firstname')}
                                error={formState.errors['user_firstname']}     
                                label={"firstname"}
                                defaultValue={deliver?.user_firstname}
                                inputStyle={{
                                    variant : "auth" , 
                                    fontSize : "sm" , 
                                    size : "lg",
                                    fontWeight : 500
                                }}                          
                            />

                        </Box>
                        <Box
                            mb={6}
                            w="40%"
                        >
                            <InputField 
                                registration={register('user_lastname')}
                                error={formState.errors['user_lastname']}     
                                label={"last name"}
                                defaultValue={deliver?.user_lastname}
                                inputStyle={{
                                    variant : "auth" , 
                                    fontSize : "sm" , 
                                    size : "lg",
                                    fontWeight : 500
                                }}                          
                            />

                        </Box>
                        <Box
                            mb={6}
                            w="40%"
                        >
                          <PhoneNumberInputField 
                                registration={register('phoneNumber')}
                                error={formState.errors['phoneNumber']}    
                                name='phone'
                                setValue={setValue}
                                label={"phone"}
                                defaultValue={deliver?.phoneNumber}
                                inputStyle={{
                                    variant : "auth" , 
                                    fontSize : "sm" , 
                                    size : "lg",
                                    fontWeight : 500
                                }}                       
                          />

                        </Box>

                        <Box
                            mb={6}
                            w="40%"
                        >
                            <InputField 
                                registration={register('password')}
                                error={formState.errors['password']}     
                                label={"password"}
                                type="password"
                                inputStyle={{
                                    variant : "auth" , 
                                    fontSize : "sm" , 
                                    size : "lg",
                                    fontWeight : 500,
                                    type: "password"
                                }}                          
                            />

                        </Box>

                        <Box
                            mb={6}
                            w="40%"
                        >
                            <DropDownField 
                                registration={register('payment_type')}
                                error={formState.errors['payment_type']}     
                                label={"type de paiment"}
                                items={[
                                    "Jour" , "Semaine","Mois" , "Années" 
                                ]}
                                defaultValue={deliver?.payment_type}
                                setValue={setValue}
                                name="payment_type"
                                inputStyle={{
                                    variant : "auth" , 
                                    fontSize : "sm" , 
                                    size : "lg",
                                    fontWeight : 500
                                }}                          
                            />

                        </Box>

                        <Box
                            mb={6}
                            w="40%"
                        >
                            <InputField 
                                registration={register('payment_tax')}
                                error={formState.errors['payment_tax']}     
                                label={"type de paiment"}
                                defaultValue={deliver?.payment_tax}
                                inputLeftAddon = {{
                                    children: <DropDownField 
                                        registration={register('payment_method')}
                                        error={formState.errors['payment_method']}     
                                        label={""}
                                        items={[
                                            "Taux","Valeur"
                                        ]}
                                        defaultValue={deliver?.payment_method}
                                        setValue={setValue}
                                        name="payment_method"
                                        inputStyle={{
                                            variant : "auth" , 
                                            fontSize : "sm" , 
                                            size : "lg",
                                            fontWeight : 500
                                        }}                          
                                    />
                                }}
                                inputStyle={{
                                    variant : "auth" , 
                                    fontSize : "sm" , 
                                    size : "lg",
                                    fontWeight : 500,
                                }}                          
                            />

                        </Box>

                        
                        


                    </Flex>
                    <Flex
                      w={"100%"}
                      justifyContent="space-evenly"
                      my={5}
                    >
                        {/* <Button
                            variant={user?.user_suspended ? "successFill" : "dangerFill"}
                            isLoading={isUpdatingClient || isUpdatingStore}
                            fontSize={"sm"}
                            onClick={()=>{
                                handleUserUpdate({
                                    user_suspended : !(user?.user_suspended ??false )
                                })
                            }}
                            w={150}
                        >
                            {
                                user?.user_suspended ? "activer" : "banner"
                            }

                        </Button> */}
                        <Button
                            variant="primaryFill"
                            type="submit"
                            isLoading={isUpdatingDeliver}
                            fontSize={"sm"}
                            w={150}
                        >
                            Sauvgarder

                        </Button>

                    </Flex>

                  </Stack>


                  
                  
                      

              </Flex>
            )
        }
    </Form>
</Box>
  )
}

DeliverForm.propTypes = {}

export default DeliverForm