
import AdminLayout from "components/layout/adminLayout/AdminLayout";
import { ActivityRoutes } from "features/activity";
import { AdsRoutes } from "features/ads/routes";
import { DeliverRoutes } from "features/delivers";
import { DeliveryRoutes } from "features/delivery";
import { OredersRoutes } from "features/orders";
import { StoreRoutes } from "features/store";
import { UserRoutes } from "features/user";
import { useAuth } from "lib/auth";
// import SocketProvider from "providers/socketProvider";
import { Navigate, Outlet, useLocation, useNavigate, useRoutes } from "react-router-dom";



const App = () => {
    const auth = useAuth()
    const navigate   = useNavigate()
    const {pathname} = useLocation()


   

    if(!auth.user){
        localStorage.setItem("previousPage" ,pathname )
        return (
            <Navigate 
                to="/auth/login"
            />
        )
        // navigate("/auth/login")
    }
    return (
        // <SocketProvider>
            <AdminLayout>
                <Outlet />
            </AdminLayout>
        // </SocketProvider>
    );
  };



export const protectedRoutes = [{
    path :  "/" , 
    element : <App /> , 
    children : [
        {
            path : "/users/*",
            element: <UserRoutes />
        },
        {
            path : "/stores/*",
            element: <StoreRoutes />
        } ,
        {
            path : "/delivers/*",
            element: <DeliverRoutes />
        } ,  
        {
            path  : "/activities/*" , 
            element :  <ActivityRoutes />
        },
        {
            path  : "/orders/*" , 
            element :  <OredersRoutes />
        },
        {
            path: "/delivery/*",
            element: <DeliveryRoutes/>
        },
        {
            path: "/ads/*",
            element: <AdsRoutes/>
        }
        
    ]
}]