import React from 'react'
import PropTypes from 'prop-types'
import AuthLayout from '../components/Layout'
import { Box, Flex, Heading, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import LoginForm from '../components/LoginForm'
import { googleLogin } from '../api/firebase'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const navigate =  useNavigate()
  const textColor  =  useColorModeValue("black.700" , "white")
  const textColorSecondary = "gray.400";
  const handleNavigate = ()=>{

    const path  =  localStorage.getItem('previousPage')
    if(path){
      navigate(path)
      return 
    }

    navigate('/')
  }

  

  return (
    <AuthLayout>
      <Box
        mt={{ base: "40px", md: "14vh" }}
      >
        <Box>
          <Heading
            color={textColor}
            fontSize="35px"
            mb={"10px"}
          >
            Sign In
          </Heading>
          <Text
            color={textColorSecondary}
            mb='36px'
            ms='4px'
            fontWeight='400'
            fontSize='md'
          >
            Enter your email and password to sign in
          </Text>
        </Box>
        <LoginForm 
          onSuccess={handleNavigate}
        
        />
      </Box>
    </AuthLayout>
  )
}

Login.propTypes = {}

export default Login