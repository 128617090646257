import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Card from 'components/card/Card'
import LineCharts from 'components/charts/LineCharts';
import BarCharts from 'components/charts/BarCharts';
import If from 'common/If'
import { usePaymentStats } from '../api/getPaymentStats';
import moment from 'moment';
import { Button, IconButton , Text,  Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { BiChevronDown } from 'react-icons/bi';
import { MdBarChart, MdOutlineStackedLineChart } from "react-icons/md"

export const data = [
	{
		name: 'Revenue',
		data: [ 50, 64, 48, 66, 49, 68 ]
	},
	{
		name: 'Profit',
		data: [ 30, 40, 24, 46, 20, 46 ]
	}
];

export const options: any = {
	chart: {
		toolbar: {
			show: false
		},
		dropShadow: {
			enabled: true,
			top: 13,
			left: 0,
			blur: 10,
			opacity: 0.1,
			color: '#4318FF'
		}
	},
	colors: [ '#4318FF', '#39B8FF' ],
	markers: {
		size: 0,
		colors: 'white',
		strokeColors: '#7551FF',
		strokeWidth: 3,
		strokeOpacity: 0.9,
		strokeDashArray: 0,
		fillOpacity: 1,
		discrete: [],
		shape: 'circle',
		radius: 2,
		offsetX: 0,
		offsetY: 0,
		showNullDataPoints: true
	},
	tooltip: {
		theme: 'dark'
	},
	dataLabels: {
		enabled: false
	},
	stroke: {
		curve: 'smooth',
		type: 'line'
	},
	xaxis: {
		type: 'numeric',
		categories: [ 'SEP', 'OCT', 'NOV', 'DEC', 'JAN', 'FEB' ],
		labels: {
			style: {
				colors: '#A3AED0',
				fontSize: '12px',
				fontWeight: '500'
			}
		},
		axisBorder: {
			show: false
		},
		axisTicks: {
			show: false
		}
	},
	yaxis: {
		show: false
	},
	legend: {
		show: false
	},
	grid: {
		show: false,
		column: {
			color: [ '#7551FF', '#39B8FF' ],
			opacity: 0.5
		}
	},
	color: [ '#7551FF', '#39B8FF' ]
};


const utints:any = {
	semain: "days", 
	moie: "days",
	année: "months"
}

const interval:any = {
	semain: "week", 
	moie: "month",
	année: "year"
}

const values:any = {
	semain: 1, 
	moie: 5,
	année: 1
}

const categories:any = {
	semain: ["Dim", "Lun", "Mar", "Mer", "Jeu" , "Ven", "Sam"] , 
	moie : [1 , 5, 10, 15, 20, 25, 30 , 35] , 
	année: [
		"Jan", "Fev", "Mar" , "Avr", "May" , "Juin" , 
		"Juil" , "Out" , "Sep" , "Oct" , "Nov", "Dec" 
	]
}

const PaymentStatistics = () => {
	const [query , setQuery] =  useState<any>({});
	const [now, setNow] =  useState<any>(null);
	const [unit , setUnit] = useState<string>("semain")
	const [type , setType ] = useState<string>("bar");
	const { data: paymentStats , refetch: refetchPaymentStats  } = usePaymentStats({query})

	useEffect( ()=>{
		setNow(moment());
	}, [])

	useEffect(()=>{
		if(now && unit){
			const from = new Date(now.clone().startOf(interval[unit]))
			const to = new Date (now.clone().endOf(interval[unit]))

			const  stepUnit = utints[unit];
			const stepValue = values[unit]

			

			setQuery({
				from,
				to,
				stepUnit,
				stepValue, 
			})
		}
	} , [now  , unit])


	useEffect( ()=>{
		refetchPaymentStats()
	}, [query])

	return(
		
		<Card h={"50vh"} >
			<Flex justifyContent="space-between" >
				<Menu>
					<MenuButton borderRadius="md" bg='secondaryGray.300' as={Button} rightIcon={<BiChevronDown />}>
						{unit}
					</MenuButton>
					<MenuList>
						<MenuItem onClick={()=>{setUnit("semain")}} >semain</MenuItem>
						<MenuItem onClick={()=>{setUnit("moie")}} >moie</MenuItem>
						<MenuItem onClick={()=>{setUnit("année")}} >année</MenuItem>
					</MenuList>
				</Menu>
				<Menu>
					<MenuButton 
						borderRadius="md" bg='secondaryGray.300' as={IconButton} 
						icon={type=="bar" ? <MdBarChart /> : <MdOutlineStackedLineChart />}
					>
						
					</MenuButton>

					
					<MenuList as={Text} >
						<If condition={type=="line"}>
							<MenuItem  as={Button}  leftIcon={<MdBarChart />} onClick={()=>{setType("bar")}}   >
								<Text ml="5" > Bar </Text>
							</MenuItem>
							
						</If>
						<If condition={type=="bar"}>
							<MenuItem  as={Button}  leftIcon={<MdOutlineStackedLineChart />} onClick={()=>{setType("line")}}   >
								<Text ml="5" > Line </Text>
							</MenuItem>
							
						</If>
					</MenuList>
				</Menu>
			</Flex>

			<If condition={ type =="line"}>
				<LineCharts  
					categories={categories[unit]}
					data={[{
						name: 'paiments',
						data: paymentStats?.payments ?? []
					} ,
					{
						name: 'profit de deliver',
						data: paymentStats?.delivery ?? []
	
					}]}
				/>

			</If>

			<If condition={ type =="bar"}>
				<BarCharts  
					categories={categories[unit]}
					data={[{
						name: 'paiments',
						data: paymentStats?.payments ?? []
					} ,
					{
						name: 'profit de deliver',
						data: paymentStats?.delivery ?? []

					}
				]}
				/>

			</If>
			
			
		</Card>
  	)
}

PaymentStatistics.propTypes = {}

export default PaymentStatistics