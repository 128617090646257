


import { mode } from '@chakra-ui/theme-tools';
export const buttonStyles = {
	components: {
		Button: {
			baseStyle: {
				borderRadius: '16px',
				boxShadow: '45px 76px 113px 7px rgba(112, 144, 176, 0.08)',
				transition: '.25s all ease',
				boxSizing: 'border-box',
				_focus: {
					boxShadow: 'none'
				},
				_active: {
					boxShadow: 'none'
				}
			},
			variants: {
                primaryFill :(props:any)=>({
                    bg: mode("primary.500" , "primary.600")(props),
                    color : "white" , 
                    fill : "white",
                    _hover :{
                        filter: "brightness(.95)",
                    }
                }),
				primaryOutline :(props:any)=>({
					border : "1px solid",
                    borderColor: mode("primary.500" , "primary.600")(props),
                    color : mode("primary.500" , "primary.600")(props) , 
                    _hover :{
                        filter: "brightness(.95)",
                    }
                }),
				dangerFill :(props:any)=>({
                    // bg: mode("red.500" , "red.600")(props),
                    bg: "red",
                    color : "white" , 
                    fill: "white",
                    stroke: "white",
                    _hover :{
                        filter: "brightness(.95)",
                    }
                }),
				successFill :(props:any)=>({
                    bg: mode("green.500" , "green.600")(props),
                    color : "white" , 
                    _hover :{
                        filter: "brightness(.95)",
                    }
                }),
                orangeFill:(props:any)=>({
                    bg: mode("orange.500" , "orange.600")(props),
                    color : "white" , 
                    fill: "white",
                    _hover :{
                        filter: "brightness(.95)",
                    }
                }),
			
			}
		}
	}
};
