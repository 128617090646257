import { axios } from "lib/axios"
import { AuthResponse } from "../types"



export type LoginCredentialdsDTO = {
    email?: string , 
    password?: string 
}

export type LoginFirebaseCredentialsDTO = {
    firebaseToken : string
}



export const login  = (data  : LoginCredentialdsDTO):Promise<AuthResponse>  =>{
    return axios.post("/auth/admin/login" , data)
}


export const firebaseLogin  = (data  : LoginFirebaseCredentialsDTO):Promise<AuthResponse>  =>{
    return axios.post("/auth/login/firebase" , data)
}