

import  {useQuery, UseQueryResult} from "react-query"
import { axios } from "lib/axios"
import { ExtractFnReturnType , QueryConfig } from "lib/react-query"
import { Deliver } from "../type"
import { extractDeliver } from "../utils/extractData"

type getDeliverQueryType = {
    limit ?: number , 
    page ?: number , 
    search ?: String,
    deliver_id?: any,
}


export const getDeliver = async  (query : getDeliverQueryType ={}) :Promise<any> =>{
    const response = await   axios.get(
        "/admin/user/deliver/single",
        {
            params : {
                ...query
            }
        }
    )

    const data  =  response.data
    return (
        extractDeliver(data)
    )


}


type FnType  =  typeof getDeliver;





type  useUsersOptions = {
    config? :any , 
    query?: getDeliverQueryType
}


export const  useDeliver :({config , query}:useUsersOptions)=>UseQueryResult<Deliver, Error> = ({config , query} = {})=>{
    return (
        useQuery(
            ["delivers/single"] , 
            ()=>getDeliver(query) , 
            config
        )
    )

}

