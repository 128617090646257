import { Avatar, Button, Center, Circle, Flex, HStack, IconButton, Image, LinkBox, LinkOverlay, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import Card from 'components/card/Card'

  
  
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useOrder } from '../api/getOrder'
import { FormattedDate, FormattedTime } from 'react-intl'
import OrderStatus from '../components/OrderStatus'
import { OrderContext } from 'contexts/orderContext';
import If from 'common/If';
import OutlineClose from 'assets/svgs/OutlineClose'
import OutlinePhone from 'assets/svgs/OutlinePhone'
import TestMap from '../components/TestMap';




const OrderDetails = () => {  
    const { 
        isOpen: isPhoneModalOpened , 
        onOpen: openPhoneModal, 
        onClose: closePhoneModal 
    } = useDisclosure() 
    const { isOpen:isMapOpened , onToggle: toggleIsMapOpened } = useDisclosure()
    const [phoneNumber, setPhoneNumber ] = useState<string>("");

    const headerBg =  useColorModeValue("secondaryGray.400" , "secondaryGray.600")
    const headColor = useColorModeValue("secondaryGray.900", "gray.500")
    const {order_id} =  useParams()

   
   

    const {data:order , refetch} =  useOrder({
        params : {order_id}}
    )


    const openNumber = (phoneNumber: string | undefined ="")=>{
        setPhoneNumber(phoneNumber);
        openPhoneModal();
    }


    useEffect(()=>{
        console.log("our order is ", order)
    } , [order])



  





   

   

    return (
        <OrderContext.Provider
            value={{order}}
        >
            <Stack
                gap={10}
                justifyContent="space-evenly"
            >
                <Modal isOpen={isPhoneModalOpened} onClose={closePhoneModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Numéro du téléphone</ModalHeader>
                        <ModalCloseButton >
                            <IconButton 
                                aria-label='close chose deliver'
                                variant={"primaryFill"}
                                icon={<OutlineClose />}
                                size={"sm"}
                                borderRadius={"md"}
                            />
                        </ModalCloseButton>
                        <ModalBody>
                        <Flex
                            justifyContent={"center"}
                            my={5}
                        >
                            <Text 
                                mx="auto" mb="6" fontWeight={"bold"} fontSize={"3xl"} color="orange.500" 
                                border={"1px solid"}
                                px={3}
                                borderColor="gray.500"
                                borderRadius={"md"}
                            >
                                {phoneNumber}

                            </Text>

                        </Flex>
                            
                                        
                                    
                        </ModalBody>


                
                    
                    </ModalContent>
                </Modal>
                <Card
                    position={"relative"}
                    py={15}
                    pt={120}
                    overflow="hidden"
                >
                    <Flex
                        bg={headerBg}
                        justifyContent={'space-between'}
                        position="absolute"
                        top={0}
                        left={0}
                        w={"100%"}
                        h={70}
                        px={5}
                        alignItems="center"

                    >
                        <HStack>
                            <Avatar 
                                src={order?.client?.clientPicture?.file_url}
                                name={order?.client?.client_name}
                            />
                            <Text
                                color={"primary.500"}
                                fontSize="md"
                                textTransform={"capitalize"}
                                fontWeight={500}
                            >
                                {`${order?.client?.client_name}`} 
                            </Text>
                            <IconButton 
                                size={"sm"}
                                aria-label='call client'
                                variant="orangeFill"
                                icon={<OutlinePhone width="20px" />}
                                onClick={()=>openNumber(order?.client?.phoneNumber)}
                            />
                        </HStack>

                        <HStack>
                        <LinkBox>
                            <Link to={`/users/${order?.client?.user_id}`}>
                            <LinkOverlay>
                            <Button 
                                variant={"primaryFill"}
                                size={"sm"}
                            >
                                Profile
                            </Button>
                            </LinkOverlay>
                            </Link>
                        </LinkBox>
                        
                        </HStack>
                    </Flex>

                    <Stack spacing={5} >
                        <Flex
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            px={10}
                            borderBottom={"1px solid"}
                            borderColor={"gray.300"}
                            pb={5}
                        >
                            <Text
                                color="gray.700"
                                fontSize={"lg"}
                                fontWeight={"semi-bold"}
                            > Store </Text>
                            <HStack
                            
                            >
                            
                            
                                <IconButton 
                                    size={"sm"}
                                    aria-label='call client'
                                    variant="orangeFill"
                                    icon={<OutlinePhone width="20px" />}
                                    onClick={()=>openNumber(order?.store?.phoneNumber)}
                                />
                                <Text
                                    color={"primary.500"}
                                    fontSize="md"
                                    textTransform={"capitalize"}
                                    fontWeight={500}
                                >
                                    {`${order?.store?.store_name}`} 
                                </Text>
                                <Avatar 
                                    src={order?.store?.storePicture?.file_url}
                                    name={order?.store?.store_name}
                                />
                            </HStack>
                        </Flex>

                        <Flex
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            px={10}
                            borderBottom={"1px solid"}
                            borderColor={"gray.300"}
                            pb={5}
                        >
                            <Text
                                color="gray.700"
                                fontSize={"lg"}
                                fontWeight={"semi-bold"}
                            > Deliver </Text>
                            <HStack
                            
                            >
                            
                                
                                <IconButton 
                                    size={"sm"}
                                    aria-label='call client'
                                    variant="orangeFill"
                                    icon={<OutlinePhone width="20px" />}
                                    onClick={()=>openNumber(order?.deliver?.phoneNumber)}
                                />
                                <Text
                                    color={"primary.500"}
                                    fontSize="md"
                                    textTransform={"capitalize"}
                                    fontWeight={500}
                                >
                                    {`${order?.deliver?.deliver_name}`} 
                                </Text>
                                <Avatar 
                                    src={order?.deliver?.deliverPicture?.file_url}
                                    name={order?.deliver?.deliver_name}
                                />
                            </HStack>
                        </Flex>

                        <Flex
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            px={10}
                            borderBottom={"1px solid"}
                            borderColor={"gray.300"}
                            pb={5}
                        >
                            <Text
                                color="gray.700"
                                fontSize={"lg"}
                                fontWeight={"semi-bold"}
                            > Nombre de la commande </Text>
                            <HStack
                            
                            >
                                <Text
                                    color={"primary.500"}
                                    fontSize="md"
                                    textTransform={"capitalize"}
                                    fontWeight={500}
                                >
                                    {`${order?.order_number}`} 
                                </Text>
                            </HStack>
                        </Flex>

                        <Flex
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            px={10}
                            borderBottom={"1px solid"}
                            borderColor={"gray.300"}
                            pb={5}
                        >
                            <Text
                                color="gray.700"
                                fontSize={"lg"}
                                fontWeight={"semi-bold"}
                            > Date de demmande </Text>
                            <HStack
                            
                            >
                                <Text
                                    color={"primary.500"}
                                    fontSize="md"
                                    textTransform={"capitalize"}
                                    fontWeight={500}
                                >

                                    <FormattedDate
                                        value={order?.createdAt}
                                        month="long"
                                        year="numeric"
                                        day="numeric"
                                    />
                                    {`  `}
                                    <FormattedTime
                                        value={order?.createdAt}
                                        hour="2-digit"
                                        minute="2-digit"
                                    />
                                
                                </Text>
                            </HStack>
                        </Flex>
                    </Stack>
            
                

                </Card>


                <If condition={order && isMapOpened}>
                    <div style={{ height: '70vh', width: '100%' }}>
                        {/* <OrderMap /> */}
                        {/* :TODO order map */}
                        <TestMap />
                    </div>

                </If>


            

                <Card
                    position={"relative"}
                    py={15}
                    pt={120}
                    overflow="hidden"
                >
                    <Flex
                        bg={headerBg}
                        justifyContent={'space-between'}
                        position="absolute"
                        top={0}
                        left={0}
                        w={"100%"}
                        h={70}
                        px={5}
                        alignItems="center"



                    >
                        <Button
                            variant={"primaryFill"}
                            px={9}
                            onClick={toggleIsMapOpened}
                        >
                            Map
                        </Button>
                        <Text
                            fontSize={"3xl"}
                            ml="auto"
                        >
                            <OrderStatus 
                                value={ order?.order_status}
                            />

                        </Text>
                    </Flex>

                    <Flex 
                        px={5}
                        py={5}
                        gap={10}
                        justifyContent={"space-between"}
                    >
                        <Stack>
                            <Text color={"primary.500"} fontWeight={"semi-bold"} fontSize={"xl"}>
                                Prix Total
                            </Text>

                            <Text
                                color="gray.700"
                                fontSize={"3xl"}
                                fontWeight={"bold"}
                            >
                                { (order?.delivery_price ?? 0 ) + (order?.order_price ?? 0)  }
                            </Text>
                        </Stack>

                        <Flex w={"60%"} gap="10" >
                            {
                                order?.order_items.map((order_item:any , _key:any)=>{
                                    return(
                                        <Stack>
                                            <Image 
                                                src={order_item?.productPhoto?.file_url}
                                                w={100}
                                                h={100}
                                                borderRadius={"md"}
                                            />
                                            <HStack 
                                                justifyContent={"space-between"}
                                            >
                                                <Text 
                                                    w={50}
                                                    fontSize={"sm"}
                                                    color={"gray.800"}
                                                >
                                                    {order_item?.product_name }
                                                </Text>
                                                <Circle
                                                    bg="primary.500"
                                                    size={7}
                                                >
                                                    <Center
                                                        borderRadius={"full"}
                                                    >
                                                        <Text 
                                                            color="white"
                                                            fontSize={"sm"}
                                                            fontWeight={"semi-bold"}
                                                        >
                                                            {order_item?.order_item_count}
                                                        </Text>

                                                    </Center>

                                                </Circle>
                                            </HStack>
                                        </Stack>
                                    )
                                })
                            }
                        </Flex>

                    </Flex>
            
                

                </Card>
            </Stack>
        </OrderContext.Provider>
        
    )
}


export default OrderDetails;


