import { mode } from '@chakra-ui/theme-tools';
export const globalStyles = {
	colors: {
        primary: {
            100: '#FBEBFF',
            200: '#F3C2FF',
            300: '#EB99FF',
            400: '#E270FF',
            500: '#9400B9',
            600: '#72008F',
            700: '#520066',
            800: '#31003D',
            900: '#210029',
    
            dark: {
                100: '#aac0fe',
                200: '#a3b9f8',
                300: '#728fea',
                400: '#3652ba',
                500: '#1b3bbb',
                600: '#24388a',
                700: '#1B254B',
                800: '#111c44',
                900: '#0b1437'
            }
        },
        gray : {
            100: '#FAFCFE',
            200: '#E2E2E2',
            
    
        },
        secondaryGray: {
			100: '#E0E5F2',
			200: '#E1E9F8',
			300: '#F4F7FE',
			400: '#E9EDF7',
			500: '#8F9BBA',
			600: '#A3AED0',
			700: '#707EAE',
			800: '#707EAE',
			900: '#1B2559'
		},
        dark: {
            100: '#aac0fe',
            200: '#a3b9f8',
            300: '#728fea',
            400: '#3652ba',
            500: '#1b3bbb',
            600: '#24388a',
            700: '#1B254B',
            800: '#111c44',
            900: '#0b1437'
        },
        orange: {
            100: "#FFDEC2",
            200: "#FFBE85",
            300: "#FF9D47",
            400: "#FF871F",
            500: "#FC7700" , 
            600: "#CC5F00", 
            700: "#A34C00",
            800: "#7A3900",
            900: "#522600",
        }
		
	},
	styles: {
		global: (props: any) => ({
			body: {
				overflowX: 'hidden',
				bg: mode('gray.100', 'dark.900')(props),
				fontFamily: "'Poppins', sans-serif",
				letterSpacing: '.2px'
			},
			input: {
				color: 'gray.700'
			},
			html: {
				fontFamily: "'Poppins', sans-serif"
			},
            ".rs-picker-menu": {
                zIndex: 2000
            }
		})
	}
};
