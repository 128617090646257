
import { Avatar, Box, Button, Collapse, Flex, HStack, LinkBox, LinkOverlay, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { useStore } from '../api/getStore'
import { Link, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import If from 'common/If'
import { useApproveStore } from '../api/approveStore'

const StoreDetails = () => {
  const headerBg =  useColorModeValue("secondaryGray.400" , "secondaryGray.600")
  const headColor = useColorModeValue("secondaryGray.900", "gray.500")
  const {store_id} =  useParams()

  const {data:store , refetch: refetchStore} =  useStore({
      params : {store_id}}
  )

  const { isLoading: isApprovingStore , mutate: approveStore , isSuccess: storeApproved } = useApproveStore()


  useEffect(()=>{
    console.log("our store is  ", store)
  } , [store])


  useEffect(()=>{
    if(storeApproved){
      refetchStore()
    }
  } , [storeApproved])


  const handleApproveStore = () =>{
    approveStore({
      query : {
        store_id
      }
    })
  }


  return (
    <Flex
    gap={10}
    justifyContent="space-evenly"
  >
    <Card
      position={"relative"}
      py={15}
      pt={120}
      overflow="hidden"
  >
      <Flex
          bg={headerBg}
          justifyContent={'space-between'}
          position="absolute"
          top={0}
          left={0}
          w={"100%"}
          h={70}
          px={5}
          alignItems="center"

      >
        <HStack>
        <Avatar 
                            src={store?.storePicture?.file_url}
                            name={store?.store_name}
                        />
                        <Text
                            color={"primary.500"}
                            fontSize="md"
                            textTransform={"capitalize"}
                            fontWeight={500}
                        >
                            {`${store?.store_name}`} 
                        </Text>
        </HStack>

        <HStack>
        <Button 
            variant={"primaryFill"}
            size={"sm"}
          >
            Commandes
          </Button>
          <LinkBox>
            <Link to={store?.has_category ? `/stores/${store_id}/categories` :
              `/stores/${store_id}/products`
            }>
              <LinkOverlay>
              <Button 
                variant={"primaryOutline"}
                size={"sm"}
              >
                Produits
              </Button>
              </LinkOverlay>
            </Link>
          </LinkBox>
          
        </HStack>
      </Flex>

      <Flex
        justifyContent={"space-evenly"}
        flexWrap={"wrap"}
        gap={10}
        p={2}
        py={10}
      >
        <Stack flexGrow={"1"}>
          <Text 
            color={headColor}
          >
            Owner
          </Text>
          <Text
            color={"primary.500"}
            fontSize={"lg"}
            fontWeight={"semi-bold"}
          >
            {`${store?.user?.user_lastname}  ${store?.user?.user_firstname}`}
          </Text>
        </Stack>
        <Stack flexGrow={"1"}>
          <Text 
            color={headColor}
          >
            Activity
          </Text>
          <Text
            color={"primary.500"}
            fontSize={"lg"}
            fontWeight={"semi-bold"}
          >
            {store?.activity_name}
          </Text>
        </Stack>
        <If condition={store?.mainActivity}>
        <Stack flexGrow={"1"}>
          <Text 
            color={headColor}
          >
            Main activity
          </Text>
          <Text
            color={"primary.500"}
            fontSize={"lg"}
            fontWeight={"semi-bold"}
          >
            {store?.mainActivity}
          </Text>
        </Stack>
        </If>
      </Flex>

      <Flex py={5} justifyContent={"flex-end"} >  
        <Collapse
          in={!store?.store_activated}
        >
          <Button  isLoading={isApprovingStore} variant={"primaryFill"} onClick={handleApproveStore} >
                Activer le store
          </Button>
        
        </Collapse>


      </Flex>
    

    </Card>
    </Flex>
    
  )
}


export default StoreDetails