

// import { useJsApiLoader } from "@react-google-maps/api";
import {AppProvider} from "providers/app"
import {AppRoutes}  from 'routes';


// const libraries = ["drawing",'places']
function App() {
  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: "AIzaSyAGtikbazdLPkXi4sm-ZbKq1QX6xtrdyOk",
  //   // libraries,
  // });
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
