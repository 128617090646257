import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Notification, useNotification } from 'stores/notifications'
import { Button, Text } from '@chakra-ui/react'
import NotificationComp from './Notification'
import {toast} from "react-toastify"

const Notifications = () => {
    const {notifications , dismissNotification , addNotification} =  useNotification()
    useEffect(()=>{
        // const snapNots =  notifications.map((notification:Notification)=>{
        //     if(notification.isNew){
        //         toast[notification.type](notification.message ,{containerId : notification.id})
        //     }
        // })

        
        
    } , [notifications])
  

    return (
        <>
            {
                notifications.map((notification:any , key : number)=>{                 
                    return(
                        <NotificationComp 
                            key={key}
                            notification={notification}
                            onDismiss={dismissNotification}
                        />
                    )
                })
            }
        </>
    )
}


export default Notifications