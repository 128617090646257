import Axios, { AxiosRequestConfig } from "axios"
import { Api_URL } from "config"
import storage from "utils/storage"



export const axios = Axios.create({
    baseURL : Api_URL
})



function authRequestInterceptor(config: AxiosRequestConfig): any {
    if(config){
        config.headers = {
            ...config.headers , 
            Accept : 'application/json' ,    
            "Access-Control-Allow-Origin": "*",
        }

        if(storage.getToken()){
            config.headers.authorization = `Bearer ${storage.getToken()}`
        }
    }
    return config;
}

axios.interceptors.request.use(authRequestInterceptor)


axios.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        const message = error.response?.data?.message || error.message;
        
        // .getState().addNotification({
        //     type: "error",
        //     title: "Error",
        //     message,
        // });
        return Promise.reject(error);
    }

)