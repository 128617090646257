import { Route, Routes } from "react-router-dom"
import ListStores from "../pages/ListStores"
import StoreDetails from "../pages/StoreDetails"
import CategoriesList from "../pages/CategoriesList"
import ProductsList from "../pages/ProductsList"

export const StoreRoutes = ()=>{
    return(
        <Routes>
            <Route  path="/" element={<ListStores />} />
            <Route  path="/demands" element={
            <ListStores 
                store_activated={false}
            />} 
            />
            <Route  path='/:store_id/categories' element={<CategoriesList />} />
            <Route  path='/:store_id/categories/:category_id' element={<ProductsList />} />
            <Route  path='/:store_id/products' element={<ProductsList />} />
            <Route  path='/:store_id' element={<StoreDetails />} />
        </Routes>
    )
}