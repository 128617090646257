import { ReactNode } from "react"
import { HashRouter as Router } from "react-router-dom"
import {ChakraProvider} from "@chakra-ui/react"
import theme from "lib/theme"
import { QueryClientProvider } from "react-query"
import { queryClient } from "lib/react-query"
import { AuthProvider } from "lib/auth"
import Notifications from "components/Notication/Notifications"
import TranslationProvider from "./translationProvider"
import SocketProvider from "./socketProvider"
import FirebaseMessagingProvider from "./FirebaseMessagingProvider"


type AppProviderProps = {
    children : ReactNode
}


export const AppProvider=(
    {
        children
    }:
    AppProviderProps
)=>{

    return (
        <FirebaseMessagingProvider>
            <ChakraProvider
                theme={theme}
            >
                <TranslationProvider  locale="fr">
                    <QueryClientProvider
                        client={queryClient}
                    >
                        <Notifications />
                        <AuthProvider>
                            <SocketProvider>
                                <Router>
                                    { 
                                        children
                                    }
                                </Router>
                            </SocketProvider>

                        </AuthProvider>

                    </QueryClientProvider>

                </TranslationProvider>
            </ChakraProvider>
        </FirebaseMessagingProvider>

    )



}