import { Box, Button, Center, Flex, Image, Icon, Input, Circle } from '@chakra-ui/react'
import DeleteIcon from 'assets/svgs/DeleteIcon'
import { FormRequestBuilder } from 'features/activity/utils/formRequestBuilder'
import React, { useEffect } from 'react'
import { AiOutlineCamera, AiOutlinePlus } from 'react-icons/ai'
import { IconButton } from 'rsuite'
import { useCreateAds } from '../apis/addAds'
import { useDeleteAd } from '../apis/deleteAds'
import { useAds } from '../apis/getAds'

const ListAds = () => {

    const {  data: adsData, refetch: refetchAds  } = useAds({})

    const { mutate: deleteAds  , isSuccess: isAddDeleted } = useDeleteAd()

    const {  mutate: createAd  , isSuccess: isAddAdded} =  useCreateAds()


    const handleChangeFiles =  (e: any)  => {
        const file = e?.target?.files?.[0]

        if(file){
            const  formData =  new FormData();
            // const deliverForm = new FormRequestBuilder({
            //     file,
            // }).getFormData()       

            formData.append("file", file)
            
            createAd(formData);
        }

    }



    useEffect(() => {
        if(isAddAdded){
            refetchAds()
        }

    }, [refetchAds, isAddAdded])

    useEffect(() => {
        if(isAddDeleted){
            refetchAds()
        }
    }, [isAddDeleted, refetchAds])

  return (
    <Flex flexWrap="wrap"  gap="15px" >

        {
            adsData?.map((ad: any)=>{
                return(
                    <Box  position="relative" borderRadius="20px" w="300px" h="300px" overflow="hidden" >
                        <Image   
                            src={ad?.file?.file_url ?? ""}
                            alt="ad"
                            w='100%'
                            h="100%"
                            objectFit="cover"
                        />

                        <Center  
                            opacity="0"
                            _hover={{
                                opacity: 1
                            }}
                        
                        top="0" zIndex="overlay" position="absolute"  w="100%"  h="100%"  bg="rgba(112, 112, 112, .8)" >
                            <IconButton 

                                onClick={()=>{
                                    deleteAds( {
                                        ad_id: {
                                            eq: ad?.ad_id
                                        }
                                    })
                                }}
                            borderRadius="full"
                                icon={
                                    <Icon fontSize="20px" fill="danger.500"  >
                                        <DeleteIcon />

                                    </Icon>
                                }
                            
                            />
                        </Center>

                    </Box>
                )
            })
        }

        <Center
            border={{base : "none" , lg :"2px solid"}}
            borderColor="primary.500"
            // borderColor="primary.500"
            w={{base : 100 , lg : 300}}
            h={{base : 100 , lg : 300}}
            borderRadius="md"
            cursor={"pointer"}
            position="relative"
        >
            <Input 
                type={"file"}
                position="absolute"
                h="100%"
                w="100%"
                opacity={0}
                cursor="pointer"
                onChange={handleChangeFiles}        
                multiple={true}
            />
                <Circle
                    bg="primary.500"
                    p={3}
                >
                    <Icon 
                        fontSize={"3xl"}
                        as={AiOutlinePlus}
                        color="white"
                        
                    />
                

                </Circle>
            </Center>

    </Flex>
  )
}

export default ListAds