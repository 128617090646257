import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import UserForm from '../components/UserForm'

const UserDetails = () => {
  return (
    <>
      <UserForm
        onSuccess={()=>{}}
      />
    </>
  )
}

UserDetails.propTypes = {}

export default UserDetails