import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import z from "zod"
import { Avatar, Box, Button, Editable, EditableInput, EditablePreview, Flex, HStack, LinkBox, LinkOverlay, Stack, Text, useColorModeValue } from '@chakra-ui/react'

import Form from 'components/form/Form'
import UserPhoto from './UserPhoto'

import {FcGoogle} from "react-icons/fc"
import { HSeparator } from 'components/separator/Seperator'
import { Link, useParams } from 'react-router-dom'
import InputField from 'components/form/InputField'
import NumberInputFieldComponent from 'components/form/NumberInputField'
import { useAuth } from 'lib/auth'
import Card from 'components/card/Card'
import { useUser } from '../api/getUser'
import { useUpdateStore } from '../api/updateStore'
import { useUpdateClient } from '../api/updateClient'
import { FormRequestBuilder } from '../utils/formRequestBuilder'
import TextAreaField from 'components/form/textAreaField'



const schema = z.object({
    user_firstname: z.string() ,
    user_lastname: z.string()  , 
    file : z.any()
    // password: z.string().min(8, 'password greater then 8'),
    // phoneNumber : z.string().regex(
    //     new RegExp("^(05|06|07)") , "phone number start with 05, 06 , 07"
    // ) , 
    // displayName : z.string()
    // min(8  , "password 8 characeres ").max(8 ,"pass should 8 characters" )
});


type IProfileForm  = {
    user_firstname : string , 
    user_lastname: string,
    // password : string , 
    // phoneNumber : number , 
    // displayName : string , 
    file : any , 
}

type ProfileFormProps = {
    onSuccess : any
}

const UserForm = (
    {
        onSuccess
    }:ProfileFormProps

) => {
    const {user_id} =  useParams()
    const {mutate : updateClient , isLoading:isUpdatingClient , isSuccess : isUpdatedClient} =  useUpdateClient()
    const {mutate : updateStore , isLoading:isUpdatingStore , isSuccess : isUpdatedStore} =  useUpdateStore()

    const {data:user , refetch} =  useUser({
        params : {user_id}}
    )

    const handleUserUpdate = (data:any)=> {
        const userForm = new FormRequestBuilder(data).getFormData()



        if(user?.type ==="client"){
            updateClient(
                {
                    data :userForm , 
                    user_id
                }
            )
        }
        if(user?.type =="store"){
            updateStore(
                {
                    data : userForm , 
                    user_id
                }
            )
        }
    }

    useEffect(()=>{
        if(isUpdatedClient || isUpdatedStore){
            refetch()
        }
    } , [isUpdatedClient , isUpdatedStore])

   
    const headerBg =  useColorModeValue("primary.500" , "primary.700")
    const brandColor =  useColorModeValue("primary.500" , "white")



    

    return (
        <Flex
            gap={10}
            justifyContent="space-evenly"
        >
            <Box
                w={400}
            >
                <Card
                    position={"relative"}
                    pt={100}
                    overflow="hidden"
                >
                    <Flex
                        bg={headerBg}
                        position="absolute"
                        top={0}
                        left={0}
                            w={"100%"}
                        h={70}
                        px={5}
                        alignItems="center"
                        gap={5}

                    >
                        <Avatar 
                            src={user?.userPicture?.file_url}
                            name={user?.displayName}
                        />
                        <Text
                            color={"white"}
                            fontSize="sm"
                            textTransform={"capitalize"}
                            fontWeight={500}
                        >
                            {`${user?.user_lastname} ${user?.user_firstname}`} 
                        </Text>
                    </Flex>
                    <Stack
                        spacing={10}
                        py={3}
                    >
                        <HStack>
                            <Text
                                w={150}
                                fontSize="sm"
                                textTransform={"capitalize"}
                            >
                                Username
                            </Text>
                            <Text
                                color={brandColor}
                            >
                                {user?.username}
                            </Text>
                        </HStack>

                        <HStack>
                            <Text
                                w={150}
                                fontSize="sm"
                                textTransform={"capitalize"}
                            >
                                phone number
                            </Text>
                            <Text
                                color={brandColor}
                            >
                                {user?.phoneNumber}
                            </Text>
                        </HStack>
                        <HStack>
                            <Text
                                w={150}
                                fontSize="sm"
                                textTransform={"capitalize"}
                            >
                                type
                            </Text>
                            <Text
                                color={brandColor}
                            >
                                {user?.type}
                            </Text>
                        </HStack>
                    </Stack>

                </Card>
            </Box>
            <Box>
                <Form<
                    IProfileForm ,
                    typeof schema 
                >
                    schema={schema}
                    onSubmit={handleUserUpdate}
                >
                    {
                        ({register , formState})=>(
                            <Card
                                position={"relative"}
                                py={15}
                                pt={120}
                                overflow="hidden"
                            >
                                <Flex
                                    bg={headerBg}
                                    position="absolute"
                                    top={0}
                                    left={0}
                                    w={"100%"}
                                    h={70}
                                    px={5}
                                    alignItems="center"

                                >
                                    <Text
                                        color={"white"}
                                        fontSize="lg"
                                        textTransform={"capitalize"}
                                        fontWeight={500}
                                    >
                                        information 
                                    </Text>
                                </Flex>
                                <Flex
                                    flexWrap="wrap"
                                    columnGap={5}
                                    justifyContent="space-evenly"
                                >
                                    <UserPhoto 
                                        photo={user?.userPicture} 
                                        name={""}
                                        registration={register('file')}
                                    />
                                    <Box>
                                        <Box
                                            mb={6}
                                        >
                                            <InputField 
                                                registration={register('user_firstname')}
                                                error={formState.errors['user_firstname']}     
                                                label={"firstname"}
                                                defaultValue={user?.user_firstname}
                                                inputStyle={{
                                                    variant : "auth" , 
                                                    fontSize : "xs" , 
                                                    size : "lg",
                                                    fontWeight : "normal"
                                                }}                          
                                            />

                                        </Box>
                                        <Box
                                            mb={6}
                                        >
                                            <InputField 
                                                registration={register('user_lastname')}
                                                error={formState.errors['user_lastname']}     
                                                label={"lastname"}
                                                defaultValue={user?.user_lastname}
                                                inputStyle={{
                                                    variant : "auth" , 
                                                    fontSize : "sm" , 
                                                    size : "lg",
                                                    fontWeight : 500,

                                                }}                          
                                            />

                                        </Box>
                                       


                                    </Box>
                                    {/* <Box
                                        mb={6}
                                        w={"100%"}
                                    >
                                        <TextAreaField 
                                            registration={register('banned_explication')}
                                            error={formState.errors['banned_explication']}     
                                            label={"explication"}
                                            defaultValue={user?.banned_explication}
                                            inputStyle={{
                                                variant : "auth" , 
                                                fontSize : "sm" , 
                                                size : "lg",
                                                fontWeight : 500
                                            }}                          
                                        />

                                    </Box> */}
                                       

                                    <Flex
                                        w={"100%"}
                                        justifyContent="space-evenly"
                                        my={5}
                                    >
                                        <Button
                                            variant={user?.user_suspended ? "successFill" : "dangerFill"}
                                            isLoading={isUpdatingClient || isUpdatingStore}
                                            fontSize={"sm"}
                                            onClick={()=>{
                                                handleUserUpdate({
                                                    user_suspended : !(user?.user_suspended ??false )
                                                })
                                            }}
                                            w={150}
                                        >
                                            {
                                                user?.user_suspended ? "activer" : "banner"
                                            }

                                        </Button>
                                        <Button
                                            variant="primaryFill"
                                            type="submit"
                                            isLoading={isUpdatingClient || isUpdatingStore}
                                            fontSize={"sm"}
                                            w={150}
                                        >
                                            Sauvgarder

                                        </Button>

                                    </Flex>
                                </Flex>

                            </Card>
                            
                        )
                    }
                </Form>
            </Box>

        </Flex>
    )
}


export default UserForm