import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import If from 'common/If'
import { Box, Button, HStack, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack, useDisclosure } from '@chakra-ui/react'
import {AiOutlineClose} from "react-icons/ai"
import Form from 'components/form/Form'
import { z } from 'zod'
import { AsyncSelect } from 'chakra-react-select'
import SelectAsyncFieldCompoent from 'components/form/selectAsyncField'
import { axios } from 'lib/axios'
import { extractDeliver, extractDelivers } from 'features/delivers/utils/extractData'
import { useUpdateOrderDeliver } from '../api/updateOrderDeliver'
import { FiEdit } from 'react-icons/fi'
import { useSocketContext } from 'contexts/socketContext'
import { ASSIGN_DELIVER, ORDER_ASSIGNED } from 'constants/socket'




const schema = z.object({
  deliver: z.any()
});


type IProfileForm  = {
  deliver: any;
}



type DeliverCellProps = {
  value : {
    deliver_name: string | null ,
    order_id: number
  }
}







const DeliverCell = ({value}: DeliverCellProps) => {
  const valRef = useRef<any>(null)
  const [tempName , setTempName] =  useState<string | null>(null);
  const  {isOpen, onClose, onOpen } = useDisclosure() 
  const { isOpen: isUpdating, onOpen: onUpdate , onClose: onUpdated ,  } = useDisclosure()
    

  

  const { socket } =  useSocketContext()


 

  


  const loadDelivers = async (search: string) =>{
    const response = await axios.get(
      "/admin/user/deliver",
      {
          params : {
            search
          }
      }
    );

    const delivers = extractDelivers(response.data.delivers);

    return (delivers)
  }


  useEffect(()=>{
    if(socket){
      socket.on(ORDER_ASSIGNED, (message: any)=>{
        if(message?.order_id === value?.order_id){
          setTempName(message?.deliver_name)
          onClose();
          onUpdated()
        }
      })

    }
    
  } , [socket, onClose, onUpdated, value ])




  
  const handleDeliverUpdate = ({deliver}: any) =>{
    // updateOrderDeliver({
    //   query: {
    //     deliver_id: deliver?.deliver?.id,
    //     order_id: value?.order_id
    //   }
    // })


    socket.emit(ASSIGN_DELIVER ,{
      deliver_id: deliver?.id,
      order_id: value?.order_id
    })

    onUpdate()

    // valRef.current.innerText = deliver?.deliver?.deliver_name;


  }




  return (
    <Box>
      <If condition={!value?.deliver_name && !tempName} 
      otherwise={
        <HStack>
          <IconButton onClick={onOpen} size="sm"  aria-label='edit deliver'  variant={"primaryFill"} icon={<FiEdit/>} />
          <Text>
            <p ref={valRef}> 
              {value?.deliver_name ?? tempName}
            </p>
          </Text>
        </HStack>
      }
      >
        <Button onClick={onOpen} variant={"primaryFill"} size={"sm"} >
          Choisir Deliver
        </Button> 
      </If>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choisir Deliver</ModalHeader>
          <ModalCloseButton >
            <IconButton 
              aria-label='close chose deliver'
              variant={"primaryFill"}
              icon={<AiOutlineClose />}
              size={"sm"}
              borderRadius={"md"}
            />
          </ModalCloseButton>

          <Form<
                    IProfileForm ,
                    typeof schema 
                >
                    schema={schema}
                    onSubmit={handleDeliverUpdate}
                >
                    {
                        ({register , formState, setValue})=>(
                          <>
                            <ModalBody>
                              <Text mb="6" fontWeight={"semi-bold"} color="primary.500" >Choisir de la liste ci dessus  </Text>
                  
                              <SelectAsyncFieldCompoent    
                                registration={register('deliver')}
                                name="deliver"
                                setValue={setValue}
                                // error={formState.errors['deliver']}
                                loadOptions={loadDelivers}
                                label=""
                                optionValue="id"
                                optionLabel="deliver_name"
                              />
                          
                          </ModalBody>
              
                          <ModalFooter>
                            <Button 
                              isLoading={isUpdating} 
                              type="submit" variant='primaryFill'>Confirmer</Button>
                          </ModalFooter>
                          </>
                          

                        )
                  }
          
          
          
          </Form>
            
        </ModalContent>
      </Modal>

    </Box>
  )
}


export default DeliverCell