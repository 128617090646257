

export const userColumns = [
    {
        Header : "" , 
        Type : "image",
        accessor : "userPicture"
    },
    {
        Header :"username",
        accessor : "username",
    },
    {
        Header : "type" , 
        accessor : "type" , 
        Type : "userType"
    },
    {
        Header : "phone number" , 
        accessor : "phoneNumber"
    } , 
    {
        Header : 'subscription date' , 
        accessor : "subscription_date" , 
        Type : "date"
    },
    
    
]