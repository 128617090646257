import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Flex, HStack, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, useColorModeValue } from '@chakra-ui/react'
import { z } from 'zod';
import Form from 'components/form/Form';
import { DateRangePicker } from 'rsuite';
import DateRangePickerField from 'components/form/DateRangePicker';
import DropDownField from 'components/form/dropdownField';
import { defaultFn } from 'utils/functions';



const schema = z.object({
    createdAt: z.any(),
    type: z.any(),
    status: z.any()
    // type: z.any()
    // password: z.string().min(8, 'password greater then 8'),
    // phoneNumber : z.string().regex(
    //     new RegExp("^(05|06|07)") , "phone number start with 05, 06 , 07"
    // ) , 
    // displayName : z.string()
    // min(8  , "password 8 characeres ").max(8 ,"pass should 8 characters" )
});


type IProfileForm  = {
    createdAt: any,
    type: any,
    status: any
}


type  FilterUserFormProps = {
    refilter: (filters: any)=> void,
    isOpen:boolean,
    onClose?: ()=>void
}


const FilterUserForm = ({refilter, isOpen , onClose=defaultFn}: FilterUserFormProps) => {
    const headerTextColor = useColorModeValue('primary.500', 'primary.600');
    
    
    const handleUpdateFilter = (filterData: any) =>{
        const data:any  = {}
        if(filterData?.createdAt){
            data["createdAt"] = {
                between: filterData?.createdAt
            }
        }

        if(filterData?.type &&  filterData?.type !== "toutes"){
            data["type"] = {
                eq: filterData?.type            
            }
        }

        if(filterData?.status){
            data["user_suspended"] = {
                eq:  filterData?.status==="banned" ? "1" : "0"            
            }
        }

        refilter(data)

    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} >
            <ModalOverlay />
            <ModalContent  borderRadius="xl" minW={{base : "80%"  , md : "60%" }} >
                <Form<
                    IProfileForm ,
                    typeof schema 
                >
                    schema={schema}
                    onSubmit={handleUpdateFilter}
                >
                    {
                        ({register , formState , getValues, reset, setValue, watch})=>(
                            <>
                                <ModalHeader>
                                    <HStack
                                        justifyContent="space-between"
                                    >
                                        <Text
                                        color={headerTextColor}
                                        >
                                        filters

                                        </Text>
                                        <Button
                                            variant="primaryFill"
                                            size="sm"
                                            type="reset"
                                            onClick={()=>reset({})}
                                        >
                                            Reinitialiser
                                        </Button>

                                    </HStack>

                                </ModalHeader>
                                <ModalBody py="40px" > 

                                    <Flex
                                        flexWrap="wrap"
                                        gap="20px"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box zIndex={2000} minW="45%" >
                                            <DateRangePickerField 
                                                registration={register('createdAt')}
                                                // error={formState.errors['subscription_date_filter']}
                                                label={"subscription date"}
                                                setValue={setValue}
                                                value={watch("createdAt")}
                                                //  defaultValue={user?.user_firstname}
                                                inputStyle={{
                                                    variant : "auth" , 
                                                    fontSize : "xs" , 
                                                    size : "lg",
                                                    fontWeight : "normal"
                                                }}      
                                            />

                                        </Box>

                                        <Box 
                                            minW="45%"
                                        >
                                            <DropDownField 
                                                registration={register('status')}
                                                // error={formState.errors['type']}     
                                                label={"status"}
                                                items={[
                                                    "banned" , "active"
                                                ]}
                                                defaultValue={"toutes"}
                                                setValue={setValue}
                                                value={watch("status")}
                                                name="status"
                                                inputStyle={{
                                                    variant : "auth" , 
                                                    fontSize : "sm" , 
                                                    size : "lg",
                                                    fontWeight : 500
                                                }}                          
                                            />

                                        </Box>

                                        <Box 
                                            minW="45%"
                                        >
                                            <DropDownField 
                                                registration={register('type')}
                                                // error={formState.errors['type']}     
                                                label={"account type"}
                                                items={[
                                                    "client" , "store"  ,"deliver"
                                                ]}
                                                defaultValue={"toutes"}
                                                setValue={setValue}
                                                value={watch("type")}
                                                name="type"
                                                inputStyle={{
                                                    variant : "auth" , 
                                                    fontSize : "sm" , 
                                                    size : "lg",
                                                    fontWeight : 500
                                                }}                          
                                            />

                                        </Box>

                                        <Button
                                            variant="primaryFill"
                                            type="submit"
                                            zIndex="3000"
                                        >
                                            Submit
                                        </Button>

                                    </Flex>

                            
                                




                                </ModalBody>

                            </>
                        )
                    }
                </Form>
            </ModalContent>
        </Modal>
    )
}

FilterUserForm.propTypes = {}

export default FilterUserForm