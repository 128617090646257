import {useEffect , useState} from 'react'
import PropTypes from 'prop-types'
import { Icon, Input, InputGroup, InputLeftElement, useColorMode, useColorModeValue } from '@chakra-ui/react'
import {AiOutlineSearch} from "react-icons/ai"
import { useDebounce } from 'hooks/useDebounce'

type SearchBarProps = {
    searching : any ,
    variant?:string ,
    borderRaduis ?:string ,  
    placeholder ?: string , 
    background ?: string , 
    [x : string] : any
}


const SearchBar = ({
    searching  , 
    variant , 
    borderRadius , 
    placeholder,
    background, 

    ...rest

}:SearchBarProps) => {
	const inputText = useColorModeValue('gray.700', 'gray.100');
	const inputBg = useColorModeValue('secondaryGray.300', 'dark.900');

    const [search , setState] = useState("")
    const  debounceSearch = useDebounce(search , 500 )
  
    useEffect( ()=>{
        searching(search);
    } ,[debounceSearch])


    return (
        <InputGroup
            borderRadius={borderRadius ? borderRadius :  "30px"}
            {...rest}
        >
            <InputLeftElement>
                <Icon 
                    as={AiOutlineSearch}
                    fontSize={"xl"}
                />

            </InputLeftElement>
            <Input 
                variant={"search"}
                fontSize="sm"
                bg={background ? background : inputBg }
                color={inputText}
                _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                placeholder={placeholder ? placeholder : "Search ... "}
                onChange={(e)=>{setState(e.target.value)}}
            />

        </InputGroup>
    )
}

SearchBar.propTypes = {}

export default SearchBar