import { Deliver } from "../type";








export const  extractDelivers : (delivers : any)=> Deliver[]  =  (delivers :  any) =>{
    
    return (
        delivers.map((deliver : any)=>{
            const authAccount = deliver?.user?.auth_accounts?.find((authAccount:any )=>{
                return(
                    authAccount.auth_account_type === "phoneNumber"
                )
            });
            return (
                {
                    id : deliver.deliver_id, 
                    deliverPicture : deliver?.file , 
                    deliver_name :  `${deliver?.user?.user_firstname}  ${deliver?.user?.user_lastname}  `, 
                    phoneNumber  : `${authAccount?.auth_account ??  ""}`
                    // activity_name : store?.activity?.activity_name ?? ""


                }
            )

        })
    )

   

} 




export const  extractDeliver : (deliver : any)=> Partial<Deliver>  =  (deliver:  any) =>{

    const authAccount = deliver?.user?.auth_accounts?.find((authAccount:any )=>{
        return(
            authAccount.auth_account_type === "phoneNumber"
        )
    });

    
    
    return (
        {
            id : deliver.deliver_id, 
            user_id: deliver?.user?.user_id,
            deliverPicture : deliver?.file , 
            user_firstname : deliver?.user?.user_firstname,
            user_lastname : deliver?.user?.user_lastname, 
            payment_type: deliver?.payment_type ?? "",
            payment_tax: deliver?.payment_tax ?? "",
            payment_method: deliver?.payment_method ?? "",
            phoneNumber  : authAccount?.auth_account ??  "", 
            deliver_name: `${deliver?.user?.user_firstname}  ${deliver?.user?.user_lastname}  `,


        }
    )

    
    

   

} 



