import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Notification } from 'stores/notifications'


import {ToastContainer , toast} from "react-toastify"

import 'react-toastify/dist/ReactToastify.css';

type NotificationProps ={
  notification : Notification , 
  onDismiss : any
} 


const NotificationComp = ({
  notification : {
    id , title , type , message
  },
  onDismiss
}:NotificationProps ) => {

  // useEffect(()=>{
  //   alert("notification ")
  //   // toast("Wow so easy !")
  // } , [])
  

  return (
    <ToastContainer
      enableMultiContainer 
      containerId={id}
    
    />
  )
}


export default NotificationComp

