import {  Box, Flex,  Text, useColorModeValue } from '@chakra-ui/react'
import Card from 'components/card/Card';
import NewDeliverForm from '../components/NewDeliverForm';

const NewDeliver = () => {
  
  
  const headerBg =  useColorModeValue("primary.500" , "primary.700")
  const brandColor =  useColorModeValue("primary.500" , "white")


  return (
    <Flex
            gap={10}
            justifyContent="space-evenly"
        >
            <Box
                w={"100%"}
            >
                <Card
                    position={"relative"}
                    pt={100}
                    overflow="hidden"
                >
                    <Flex
                        bg={headerBg}
                        position="absolute"
                        top={0}
                        left={0}
                            w={"100%"}
                        h={70}
                        px={5}
                        alignItems="center"
                        gap={5}

                    >
                        <Text
                            color={"white"}
                            fontSize="sm"
                            textTransform={"capitalize"}
                            fontWeight={500}
                        >
                            New deliver
                           
                        </Text>
                    </Flex>
                    <NewDeliverForm />
                </Card>
            </Box>

        </Flex>
  )
}


export default NewDeliver