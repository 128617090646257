import { Box, Flex, Stack } from "@chakra-ui/react"
import { menuItemsType } from "types/routes"
import SideBarBrand from "./SideBarBrand"
import SidebarLinks from "./SidebarLinks"


type SidebarContentProps = {
    menuItems : menuItemsType[]
}


const SidebarContent = (
    {
        menuItems
    }:SidebarContentProps
) => {
  return (
    <Flex
        pt={25}
        direction="column"
        // height={"100%"}
        borderRadius={"2xl"}
    >
        <SideBarBrand />
        <Stack
            mt={"8px"}
            ps="20px"
            mb="auto"
        >
            <Box
            >
                <SidebarLinks 
                    menuItems={menuItems}
                />
            </Box>
        </Stack>

    </Flex>
  )
}


export default SidebarContent

