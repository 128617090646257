import React from 'react'
import PropTypes from 'prop-types'
import { Text, useColorModeValue } from '@chakra-ui/react'

type CellProps = {
    value : string
}

const Cell = (
    {
        value
    }:CellProps
) => {
    
    const textColor = useColorModeValue("gray.700" , "white")
  return (
    <Text 
    textTransform={"capitalize"}
    fontSize='sm' 
    color={textColor}
    px={2}
>
    {value}
</Text>
  )
}


export default Cell