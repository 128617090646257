

import  {useQuery, UseQueryResult} from "react-query"
import { axios } from "lib/axios"
import { ExtractFnReturnType , QueryConfig } from "lib/react-query"
import { Activity } from "../type"
import { extractActivity } from "../utils/extractdata"

type getActivitysQueryType = {
    activity_id?: any
}


export const getActivity = async  (query : getActivitysQueryType ={}) :Promise<Partial<Activity>> =>{
    let data  = {}
   
    if(query.activity_id){

        const response = await   axios.get(
            `/activity/single` , 
            {
                params  :{
                    activity_id : {
                        eq : query.activity_id
                    }
                }
            }
        )
    
        data  =  response.data
    }
    
    return (
        extractActivity(data)
    )


}


type FnType  =  typeof getActivity;





type  useActivitysOptions = {
    config? :any , 
    query?: getActivitysQueryType
}


export const  useActivity :({config , query }:useActivitysOptions)=>UseQueryResult<Activity, Error> = ({config , query} = {})=>{
    return (
        useQuery(
            ["activity"] , 
            ()=>getActivity(query) , 
            config
        )
    )

}

