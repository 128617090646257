import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Box, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import Card from 'components/card/Card';
import { useDeliver } from '../api/getDeliver';
import DeliverForm from '../components/DeliverForm';
import { DeliverContext } from 'contexts/deliverContext';
import { useParams } from 'react-router-dom';
import { useUpdateDeliver } from '../api/updateDeliver';

const DeliverDetails = () => {
  const { deliver_id } =  useParams();
  const {mutate : updateDeliver , isLoading:isUpdatingDeliver , isSuccess : isUpdatedDeliver} =  useUpdateDeliver()
   
  const { data : deliver } = useDeliver({
    query:{
      deliver_id: {
        "eq" : deliver_id
      }
    }
  });
  
  const headerBg =  useColorModeValue("primary.500" , "primary.700")
  const brandColor =  useColorModeValue("primary.500" , "white")


  return (
    <Flex
            gap={10}
            justifyContent="space-evenly"
        >
            <Box
                w={"100%"}
            >
                <Card
                    position={"relative"}
                    pt={100}
                    overflow="hidden"
                >
                    <Flex
                        bg={headerBg}
                        position="absolute"
                        top={0}
                        left={0}
                            w={"100%"}
                        h={70}
                        px={5}
                        alignItems="center"
                        gap={5}

                    >
                        <Avatar 
                            src={deliver?.deliverPicture?.file_url}
                            name={deliver?.deliver_name}
                        />
                        <Text
                            color={"white"}
                            fontSize="sm"
                            textTransform={"capitalize"}
                            fontWeight={500}
                        >
                            {deliver?.deliver_name} 
                           
                        </Text>
                    </Flex>
                    <DeliverContext.Provider
                      value={{
                        deliver,
                        updateDeliver: (data: any)=>updateDeliver({
                          data, 
                          user_id: deliver?.user_id
                        }),
                        isUpdatingDeliver,
                        isUpdatedDeliver,
                      }}
                    >
                      <DeliverForm />

                    </DeliverContext.Provider>

                </Card>
            </Box>

        </Flex>
  )
}


export default DeliverDetails