import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useJsApiLoader } from '@react-google-maps/api'
import { GoogleMap } from '@react-google-maps/api'

import { Box, Button, Flex, HStack, Input, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react'
import Form from 'components/form/Form'
import InputField from 'components/form/InputField'
import { z } from 'zod'
import { useCreateDeliverySetting } from '../api/createDeliverySetting'
import { useNavigate } from 'react-router-dom'


const libraries = ["drawing",'places']






const schema = z.object({
  radius: z.number() ,
});


type IProfileForm  = {
  radius : number ,
}


const options = [
  "km" , "fixed"
]




const DeliveryPriceSettingMap = () => {
  const navigate =  useNavigate();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDIZM1z_MdQjvOxz97pNI60plA7L8KEEMs",
    libraries,
  })
  const { mutate: createDeliverySetting , isSuccess: created,   isLoading: isCreating} = useCreateDeliverySetting()
  const radiusRef =  useRef<any>(null)
  const priceRef  = useRef<any>(0);
  const unityRef =  useRef<any>(null)
  const regionRef =  useRef<any>(null)
  const [currentPosition , setCurrentPosition ] = useState({})
  const [circle , setCircle] =  useState<any>(null)
  const [map, setMap ] =  useState<any>(null)



  useEffect(()=>{
    if(created){
      navigate("/delivery")
    }
  } , [created])


  const onMapLoad = (map: any) => {
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        setCurrentPosition(pos)
    })

    setMap(map);
    // mapDirectionInitialiser(origin)
    // google.maps.event.addListener(map, "bounds_changed", () => {
    //   console.log(map.getBounds());
    //   this.setState({ bounds: map.getBounds() });
    // });
  };


  





  if(!isLoaded){
    return (
        <div>
            loading
        </div>
    )
  }

  const handleChangeCircle = (radius: number) =>{
    circle.setRadius(radius);
  }


  const handleCreateDeliverySetting = ()=>{
    if(circle){
      
      const data = {
        radius: radiusRef.current.value , 
        unity: unityRef.current.innerText.toLowerCase(), 
        price: priceRef.current.value,
        region_name : regionRef.current.value,
        address: {
          lat: circle.getCenter().lat(),
          lng: circle.getCenter().lng()
        }
      }

        
      console.log("our data is ", data)
      createDeliverySetting(data);


    }

  }



  const handleMapClick = (t: any  ) =>{
    if(!circle){
      const  { latLng } = t;
      
      const newCircle = new google.maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map,
        draggable: true,
        center:  {
          lat: latLng.lat(),
          lng: latLng.lng()
        },
        radius: parseInt(radiusRef.current.value) *  1000,
      });

      setCircle(newCircle)
      // setMarker(
      //   {
      //   lat: latLng.lat(),
      //   lng: latLng.lng()
      // }
      // )
    }
  }


  const onUnityTypeChange = (str: String)=>{
    unityRef.current.innerText = str;
  }


  

  return (
    <>
      <Flex gap={10} py={10} bg="white"  px={5} borderRadius={5}  flexWrap={"wrap"} alignItems={"flex-end"}>
        <Stack>
          <Text>
            Nom de region
          </Text>
          <Input 
            ref={regionRef}
            defaultValue={""}
            variant="auth"  
            fontSize ="sm"  
            size="lg" 
            fontWeight={500}
            type="text"
          />

        </Stack>

        <Stack>
          <Text>
            Distance
          </Text>
          <Input 
            onChange={(e:any)=>{handleChangeCircle(parseInt(e.target.value)*1000)}}
            ref={radiusRef}
            defaultValue={30}
            variant="auth"  
            fontSize ="sm"  
            size="lg" 
            fontWeight={500}
            type="number"
          />
        </Stack>
        <Stack>
          <Text>
            Unité
          </Text>
          <Menu>
            <MenuButton>
              <Button
                variant="primaryFill"
              >
                <Text
                  textTransform={'capitalize'}
                >
                  <p ref={unityRef}>
                  km

                  </p>
                </Text>

              </Button>


            </MenuButton>
            <Box
              position={"relative"}
              bg="red"
            >
              <MenuList
              >
                {
                  options?.map((option:string , key:any)=>{
                    return(
                      <MenuItem
                        onClick={()=>{onUnityTypeChange(option)}}
                        key={key}
                      >
                          <Text
                            textTransform={'capitalize'}
                          >
                            {option}
                          </Text>
                      </MenuItem>
                    )

                  })

                }

              </MenuList>

            </Box>
          </Menu>


        </Stack>
              
        <Stack>
          <Text>
            Prix
          </Text>
          <Input 
            ref={priceRef}
            defaultValue={300}
            variant="auth"  
            fontSize ="sm"  
            size="lg" 
            fontWeight={500}
            type="number"
          />
        </Stack>

        <Button onClick={handleCreateDeliverySetting} variant="primaryFill" isLoading={isCreating} >
          Confirmer
        </Button>





      </Flex>
      <GoogleMap
        center={{lat: 36.0864938, lng: 4.645035}}
        zoom={15}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        onLoad={onMapLoad}
        options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
        
        }}

        onClick={handleMapClick}
      >
          
        {/* <If condition={marker && radius}>
          <Circle
            center={marker}
            radius={parseInt(radius)}
            draggable={true}
            options={{
              strokeColor: "#ff0000"
            }}
            // options={place.circle.options}
          />
        </If> */}
      


        



      </GoogleMap>
    </>
  )
}

export default DeliveryPriceSettingMap



