import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import FieldWrapper, { FieldWrapperPassThroughProps } from './FieldWrapper'
import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure } from '@chakra-ui/react'
import { FiEye } from "react-icons/fi"
import {UseFormRegisterReturn} from "react-hook-form"
import If from 'common/If'
import { BsChevronDown } from 'react-icons/bs'

type InputFieldProps = FieldWrapperPassThroughProps  & {
    registration : Partial<UseFormRegisterReturn> , 
    inputStyle?: any , 
    name: string,
    items?: any,
    setValue?:any,
    value?:any,
    defaultValue?:any,
    type?:string
}


const DropDownField = (
   {
    registration , 
    error , 
    label  , 
    items,
    name,
    defaultValue,
    setValue=()=>{},
    value="",
    type="text",
    inputStyle={} 
   }:InputFieldProps

) => {
  const buttonRef = useRef<any>(null)
  const [showedValue , setShowValue] = useState("");
  const {isOpen: isShowPasswrd ,onToggle: OnToogleShowPassword  } =  useDisclosure()



  useEffect(()=>{
    buttonRef.current.innerText =  defaultValue
  } , [defaultValue])
  useEffect(()=>{
    if(!value){
      buttonRef.current.innerText =  defaultValue
    }
  } , [value, buttonRef, defaultValue])


  const handleValueChange = (item: string)=>{
    buttonRef.current.innerText =  item
    setValue(name , item)
  }

  return (
    <FieldWrapper
      error={error}
      label={label}
    >

        <Menu>
            <MenuButton as={Button} rightIcon={<BsChevronDown />}
             
             >
              <div
                ref={buttonRef}
              >
              </div>
              {/* {showedValue} */}

            </MenuButton>
            <MenuList>
              {
                items.map((item:string , key:any)=>(
                  <MenuItem 
                    key={key} 
                    onClick={()=>handleValueChange(item)}
                  >{item}</MenuItem>

                ))
              }
            </MenuList>
        </Menu>
      
    </FieldWrapper>
  )
}

export default DropDownField