import { mode } from '@chakra-ui/theme-tools';
export const inputStyles = {
	components: {
		Input: {
			baseStyle: {
				field: {
					fontWeight: 400,
					borderRadius: '8px'
				}
			},

			variants: {
				
				auth: (props: any) => ({
					field: {
						fontWeight: '500',
						color: mode('black.700', 'white')(props),
						bg: mode('transparent', 'transparent')(props),
						border: '2px solid',
						borderColor: mode('gray.500', 'rgba(135, 140, 189, 0.3)')(props),
						borderRadius: '16px',
						_placeholder: { color: 'secondaryGray.600', fontWeight: '400' },
						_hover: {
							borderColor: mode('primary.500', 'primary.500')(props),
						}
					}
				}),
				search: () => ({
					field: {
						border: 'none',
						py: '11px',
						borderRadius: 'inherit',
						_placeolder: { color: 'gray.700' }
					}
				}),
				primary: (props: any) => ({
					field: {
						fontWeight: '500',
						color: mode('black.900', 'white')(props),
						bg: mode('transparent', 'transparent')(props),
						border: '1px solid',
						borderColor: mode('gray.500', 'rgba(135, 140, 189, 0.3)')(props),
						borderRadius: '16px',
						_placeholder: { color: 'gray.600', fontWeight: '400' } , 
						_hover:{
							borderColor: mode('primary.500', 'rgba(135, 140, 189, 0.3)')(props),
						
						}
					}
				}),
			}
		},
		NumberInput : {
			baseStyle: {
				field: {
					fontWeight: 400,
					borderRadius: '8px'
				}
			},

			variants: {
				primary: (props: any) => ({
					field: {
						fontWeight: '500',
						color: mode('black.900', 'white')(props),
						bg: mode('transparent', 'transparent')(props),
						border: '1px solid',
						borderColor: mode('gray.500', 'rgba(135, 140, 189, 0.3)')(props),
						borderRadius: '16px',
						_placeholder: { color: 'gray.600', fontWeight: '400' } , 
						_hover:{
							borderColor: mode('primary.500', 'rgba(135, 140, 189, 0.3)')(props),
						
						}
					}
				})
			}

		} ,
		PinInput: {
			baseStyle: {
				field: {
					fontWeight: 400,
					borderRadius: '8px'
				}
			},

			variants: {
				primary: (props: any) => ({
					field: {
						fontWeight: '500',
						color: mode('black.900', 'white')(props),
						bg: mode('transparent', 'transparent')(props),
						border: '1px solid',
						borderColor: mode('gray.500', 'rgba(135, 140, 189, 0.3)')(props),
						borderRadius: '16px',
						_placeholder: { color: 'gray.600', fontWeight: '400' } , 
						_hover:{
							borderColor: mode('primary.500', 'rgba(135, 140, 189, 0.3)')(props),
						
						}
					}
				}),
			}
		},
		Textarea: {
			baseStyle: {
				field: {
					fontWeight: 400,
					borderRadius: '8px'
				}
			},

			variants: {
				
				auth: {
					fontWeight: '500',
					color:'black.700',
					bg: 'transparent',
					border: '1px solid',
					borderColor: 'secondaryGray.100',
					borderRadius: '16px',
					_placeholder: { color: 'secondaryGray.600', fontWeight: '400' }
				
				},
				search: () => ({
					field: {
						border: 'none',
						py: '11px',
						borderRadius: 'inherit',
						_placeolder: { color: 'gray.700' }
					}
				}),
				primary: (props: any) => ({
					field: {
						fontWeight: '500',
						color: mode('black.900', 'white')(props),
						bg: mode('transparent', 'transparent')(props),
						border: '1px solid',
						borderColor: mode('gray.500', 'rgba(135, 140, 189, 0.3)')(props),
						borderRadius: '16px',
						_placeholder: { color: 'gray.600', fontWeight: '400' } , 
						_hover:{
							borderColor: mode('primary.500', 'rgba(135, 140, 189, 0.3)')(props),
						
						}
					}
				}),
			}
		},
	}
};
