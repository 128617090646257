import {useState} from 'react'
import PropTypes from 'prop-types'
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import NavbarLinks from './NavbarLinks';

const Navbar = () => {
    const [pageText , setPageText] = useState("users");
    const [breadcrumbs , setBreadCrumbs] = useState<any>([
        {
            path : "/users", 
            label : "pages"
        },
        {
            path : "/users", 
            label : "texts"
        }
    ])


    let mainText  = useColorModeValue("gray.900" , "white")
    let secondaryText = useColorModeValue("gray.700" , "white")
    let navbarBackdrop = "blur(20px)"
    // let navbarBg = useColorModeValue("primary.300" , "pramary.100")
    let navbarBg = useColorModeValue("rgba(244, 247, 254, 0.2)" , "rgba(11,20,55,0.5)")    
    let paddingX = "15px"

    return (
        <Box
            position={"fixed"}
            bg={navbarBg}
            backdropFilter={navbarBackdrop}
            px={paddingX}
            py={"8px"}
			right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
			top={{ base: '12px', md: '16px', xl: '18px' }}
            w={{
				xl: 'calc(100vw - 350px)',
			}}
            zIndex={1000}
        >
            <Flex
                alignItems={"center"}
            >
                <Box
                    mb={{ sm: '8px', md: '0px' }}
                >
                    {/* <Breadcrumb>
                        {
                            breadcrumbs.map((breadcrumb:any , key:any)=>{
                                return(
                                    <BreadcrumbItem
                                        color={secondaryText}
                                        fontSize={"sm"}
                                        mb={"5px"}
                                    >
                                        <BreadcrumbLink
                                            href={breadcrumb.path}
                                        >
                                            {breadcrumb.label}
                                        </BreadcrumbLink>

                                    </BreadcrumbItem>
                                )
                            })
                            
                        }

                    </Breadcrumb>
                    <Box>
                        <Text
                            color={mainText}
                            fontSize="3xl"
                            textTransform='capitalize'
                            fontWeight={"bold"}
                        >
                            {pageText}

                        </Text>
                    </Box> */}

                </Box>
                <Box
                    ms={"auto"}
                >
                    <NavbarLinks />

                </Box>

            </Flex>


        </Box>
    )
}

Navbar.propTypes = {}

export default Navbar