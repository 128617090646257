import { axios } from "lib/axios"
import { useMutation } from "react-query"
import { useNotification } from "stores/notifications";







export const useUpdateStore= ()=>{
    const {addNotification} = useNotification()


    
    return useMutation({
        onMutate: async (newReparation:any) => {
            
            // const reparationReq = new FormRequestBuilder(newReparation).getFormData()

            // // const reparation = await createReparation(reparationReq);

            // const reparation = await queryClient.setQueryData()


            // return reparation

            


        
        },
        // onError: (_, __, context: any) => {
        // if (context?.previousComments) {
        //     queryClient.setQueryData(
        //     ["comments", discussionId],
        //     context.previousComments
        //     );
        // }
        // },
        onSuccess: () => {
            addNotification({
                type: "success",
                message: "store updated",
            });
        } , 
        mutationFn : updateStore
    });
}




export const updateStore  =async (data :any ):Promise<any>  =>{
    const profile =  await   axios.put(
        `/admin/user/${data.user_id}/store`, 
        data.data
    )
    return profile
    
}