import { Route, Routes } from "react-router-dom"
import DeliverySettings from "../pages/DeliverySettings"
import NewDeliveryPriceSetting from "../pages/NewDeliveryPriceSetting"

export const DeliveryRoutes = ()=>{
    return(
        <Routes>
            <Route  path="" element={<DeliverySettings />} />
            <Route path="/newPrice" element={<NewDeliveryPriceSetting/>} />
        </Routes>
    )
}