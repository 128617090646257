import { Route, Routes } from "react-router-dom"
import ListDelivers from "../pages/ListDelivers"
import DeliverDetails from "../pages/DeliverDetails"
import NewDeliver from "../pages/NewDeliver"

export const DeliverRoutes = ()=>{
    return(
        <Routes>
            <Route  path="/" element={<ListDelivers />} />
            <Route  path="/new" element={<NewDeliver />} />
            <Route  path="/:deliver_id" element={<DeliverDetails />} />
        </Routes>
    )
}