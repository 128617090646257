import { axios } from "lib/axios"
import { AuthResponse } from "../types"



export type RegisterCredentialsDTO = {
    email : string , 
    password : string , 
    displayName : string
}


export const registerWithEmailAndPassword = async (data : RegisterCredentialsDTO):Promise<AuthResponse>=>{
    return axios.post("/auth/register" , data)
}