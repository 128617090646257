import React from 'react'
import PropTypes from 'prop-types'
import { Badge, Text } from '@chakra-ui/react'

const statuses:any = {
  pending : "en attende de validation",
  approved: "en attende de  livreur",
  assigned: "livreur assigné",
  on_way: "en attende de livraison",
  taken: "en attende de livraison",
  completed: "livré"
}

const colors:any ={
  pending : "",
  approved: "orange",
  assigned: "blue",
  on_way: "purple",
  taken: "purple",
  completed: "green"
}


const OrderStatus = (order_status:any) => {
  return (
      <Badge borderRadius={"md"} colorScheme={colors[order_status?.value]} p={"1"}>{statuses[order_status?.value]}</Badge>

  )
}


export default OrderStatus