

import  {useQuery, UseQueryResult} from "react-query"
import { axios } from "lib/axios"
import { extractCategories } from "../utils/extractdata"
import { Category } from "../type"

type getUsersQueryType = {
    limit ?: number , 
    page ?: number , 
    search ?: string
}


export const getCategories = async  (query:any={}) :Promise<CategoriesResponse> =>{
    
    const response = await   axios.get(
        "/admin/store/category",
        {
            params :{
                ...query 
            }
        }
    )

    const {categories , ...categoryData}  =  response.data
    return (
        {
            ...categoryData ,
            categories: extractCategories(categories ?? [])

        }
    )


}


type FnType  =  typeof getCategories;





type  useCategoriesOptions = {
    config? :any  , 
    query?:any
}



type CategoriesResponse  = {
    count?: number,
    categories: Category[]
}


export const  useCategories :({config , query}:useCategoriesOptions)=>UseQueryResult<CategoriesResponse, Error> = ({config , query} = {})=>{
    
    
    return (
        useQuery(
            ["categories"] , 
            ()=>getCategories(query) , 
            config
        )
    )

}

