import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import FieldWrapper, { FieldWrapperPassThroughProps } from './FieldWrapper'
import { Input, Switch, Textarea } from '@chakra-ui/react'

import {UseFormRegisterReturn} from "react-hook-form"
import { defaultColumn } from 'react-table'

type InputFieldProps = FieldWrapperPassThroughProps  & {
    registration : Partial<UseFormRegisterReturn> , 
    inputStyle?: any,
    defaultValue?: boolean
}


const SwitchField = (
   {
    registration , 
    error , 
    label  ,
    defaultValue=false,
    inputStyle={}
   }:InputFieldProps

) => {

    const [checked,setChecked] = useState(false);


    // useEffect(()=>{
    //     alert(defaultValue)
    //     setChecked(defaultValue)
    // } , [defaultValue])


    const toogleChecked  = ()=>{
        setChecked(!checked)
    }


    return (
        <FieldWrapper
            error={error}
            label={label}
        >
            <Switch 
                {...registration}
                {...inputStyle}
                onChange={toogleChecked}
                defaultChecked={defaultValue} 
            />
        </FieldWrapper>
    )
}


export default SwitchField