import { Activity } from "../type"









export const  extractActivities : (activities : any)=> Activity[]  =  (activities :  any) =>{
    
    return (
        activities.map((activity : any)=>{
            return (
                {
                    activity_id : activity?.activity_id, 
                    activity_name : activity?.activity_name , 
                    activityPic : activity?.file


                }
            )

        })
    )

   

} 




export const  extractActivity : (activity : any)=> Partial<Activity>  =  (activity:  any) =>{
    
    return (
        {
            activity_id : activity?.activity_id, 
            activity_name : activity?.activity_name , 
            activityPic : activity?.file,
            activity_has_category: activity?.activity_has_category

        }
    )

    
    

   

} 