import { isConstructorDeclaration } from "typescript"








export class FormRequestBuilder{
    private formData:any

    constructor(data:any){
        this.formData = new FormData()
        this.createFormData(data)
    }


    createFormData(data:any  , root = ""){


        if(data instanceof File){
            console.log("data here  file ")
            this.formData.append(`${root}` ,data)
        }else if(data && typeof(data) == "object"){
            if(Array.isArray(data)){
                console.log("we are getiing array here ");
                data.map((val , id)=>{
                    if(val instanceof File){
                        let key = root ;
                        this.createFormData(val  , key)
                    }else{
                        let key = `${root}[${id}]`
                        this.createFormData(val  , key)
                    }
    
                })
            }
            else{
                Object.keys(data).map(el=>{
                    let key = !root ? el  :  `${root}[${el}]`
                    if(data[el] instanceof File){
                        let key = root ;
                        this.createFormData(data[el]  , key)
                    }else if(data[el] && typeof(data[el]) == "object"){
                        this.createFormData(data[el]  , key)
                    }else{
                        this.formData.append(key ,data[el] )
                    }
                })
    
            }
        }else{
            this.formData.append(root ,data )
        }
    
        
            
        
        
        
    
    
    
    }


    getFormData(){
        return this.formData
    }

}