
import { Order, OrderItem } from "../types";








export const  extractOrders : (orders : any)=> Order[]  =  (orders :  any) =>{
    
    return (
        orders.map((order : any)=>{
            
            return (
                {
                    id : order.order_id, 
                    client_name: `${order?.client?.user?.user_firstname} ${order?.client?.user?.user_lastname}`,
                    store_name: `${order?.store?.store_name}`,
                    order_number: `${order?.order_id}`,
                    deliver:{
                        deliver_name: order?.deliver ? `${order?.deliver?.user?.user_firstname} ${order?.deliver?.user?.user_lastname}` : null,
                        order_id: order.order_id
                    },
                    order_status: order?.order_status
                   

                }
            )

        })
    )

   

} 



export const  extractOrder : (order : any)=> Order  =  (order :  any) =>{
    const clientAuthAcccount =  order?.client?.user?.auth_accounts.find((auth_account:any)=>(
        auth_account?.auth_account_type === "phoneNumber"
    ))

    const deliverAuthAcccount =  order?.deliver?.user?.auth_accounts.find((auth_account:any)=>(
        auth_account?.auth_account_type === "phoneNumber"
    ))

    const storeAuthAcccount =  order?.store?.user?.auth_accounts.find((auth_account:any)=>(
        auth_account?.auth_account_type === "phoneNumber"
    ))

    const  client = {
        client_name: `${order?.client?.user?.user_firstname} ${order?.client?.user?.user_lastname}`,
        phoneNumber: clientAuthAcccount?.auth_account,
        clientPicture: order?.client?.file,
        user_id: order?.client?.user?.user_id
    }

    const  deliver = {
        exist: order?.deliver_id !== null , 
        deliver_id: order?.deliver?.deliver_id,
        deliver_name: `${order?.deliver?.user?.user_firstname} ${order?.deliver?.user?.user_lastname}`,
        phoneNumber: deliverAuthAcccount?.auth_account,
        deliverPicture: order?.deliver?.file
    }
    const  store = {
        store_name: `${order?.store?.store_name}`,
        phoneNumber: storeAuthAcccount?.auth_account,
        storePicture: order?.store?.file
    }
    
    return (
        {
            id : order.order_id, 
            order_number: order.order_id,
            createdAt: order.createdAt,
            order_status: order.order_status,
            store,
            client,
            deliver,
            order_items: extractOrderItems(order?.order_items),
            delivery_price: order?.delivery_price ?? 0,
            order_price: order?.order_price ?? 0,
            deliverAddress: order?.deliver?.address,
            storeAddress: order?.store?.address,
            clientAddress: order?.address

            

        }
        

    )

   

} 

export const  extractOrderItems : (orderItems : any)=> OrderItem  =  (orderItems :  any) =>{

    return (
        orderItems.map((orderItem:any)=>{
            return({
                product_name: orderItem?.product?.product_name,
                order_item_count: orderItem?.order_item_count,
                productPhoto: orderItem?.product?.file
            })
        })
    )
    
   

   

} 




