

import  {useQuery, UseQueryResult} from "react-query"
import { axios } from "lib/axios"
import {Activity} from "../type"
import { extractActivities } from "../utils/extractdata"

type getUsersQueryType = {
    limit ?: number , 
    page ?: number , 
    search ?: string
}


export const getActivities = async  (query:any={}) :Promise<Activity[]> =>{
    
    const response = await   axios.get(
        "/activity",
        {
            params :{
                ...query 
            }
        }
    )

    const data  =  response.data
    return (
        extractActivities(data)
    )


}


type FnType  =  typeof getActivities;





type  useActivitiesOptions = {
    config? :any  , 
    query?:any
}


export const  useActivities :({config , query}:useActivitiesOptions)=>UseQueryResult<Activity[], Error> = ({config , query} = {})=>{
    
    
    return (
        useQuery(
            ["activities"] , 
            ()=>getActivities(query) , 
            config
        )
    )

}

