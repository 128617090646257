
import { Box } from "@chakra-ui/react"
import Layout from "../components/Layout"
import StoresList from "../components/StoresList"
import { useStores } from "../api/getStores"
import { StoresContext, useStoresContext } from "contexts/storesContext"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"


type ListStoresProps = {
  store_activated?: boolean
}

const ListStores =({store_activated= true}:ListStoresProps) => {
  const location =  useLocation()
  const [search, setSearch] =  useState("")
  const [pageIndex, setPageIndex ] =  useState(1);
  const {  data : stores , refetch: getStores }  = useStores({
    query : {
      limit :  5,
      page : pageIndex , 
      search: search??"",
      "store_activated": {
        "bool": store_activated,
      },
    }
  })
  useEffect(()=>{
    getStores({})
  } , [pageIndex, search, location])
  // useEffect(()=>{
  //   getStores({})
  // } , [search])
  
  
  return (
    <StoresContext.Provider
      value={{
        stores,
        getStores,
        pageIndex,
        setPageIndex,
        setSearch,
      }}
    >
      <StoresList />
    </StoresContext.Provider>
  )
}


export default ListStores