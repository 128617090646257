import { Box, Flex, HStack, Icon, Text, useColorModeValue } from "@chakra-ui/react"
import { NavLink, useLocation } from "react-router-dom"
import { menuItemsType } from "types/routes"

type SideBarLinksProps = {
  menuItems : menuItemsType[]
}


const SideBarSubLinks = (
  {
    menuItems 
  } :SideBarLinksProps

) => {
  let location = useLocation()
  let activeColor = useColorModeValue("gray.900" , "white");
  let activeIcon = useColorModeValue("primary.500" , "white")
  let textColor = useColorModeValue("gray.700" , "white" )
  let brandColor= useColorModeValue('primary.500' , "primary.400")



  // verify that the menu is active or not
  const activeMenu = (menuPath:string)=>{
    return (location.pathname.includes(menuPath))
  }


  return (
    <>
      {
        menuItems.map((menuItem :menuItemsType , key:any)=>{
          if(menuItem.type === "title"){
            return (
              <Text
                my={2}
                textTransform="uppercase"
                fontSize={"sm"}
                fontWeight="bold"
              >
                {menuItem.name}
              </Text>
            )

          }else{
            return(
              <NavLink
                key={key}
                to={menuItem?.path ?? "/"}
              >
                <Box
                
                >
                  <HStack
                    spacing={'22px'}
                    py={"5px"}
                    ps={"30px"}
                  >
                    <Flex
                      w="100%"
                      alignItems={"center"}
                      justifyContent="center"
                    >
                      <Text
                        me="auto"
                        color={activeMenu(menuItem?.activeKey??"") ? activeColor :  textColor}
                        fontWeight={activeMenu(menuItem?.activeKey??"") ? "semibold" :  "normal"}
                        fontSize="sm"
                        textTransform={"capitalize"}
                        
                          
                      >
                        {menuItem.name}
                      </Text>
                    </Flex>
                    <Box
                          h='36px'
                          w='2px'
                          bg={activeMenu(menuItem?.activeKey?.toLocaleLowerCase()??"") ? brandColor : 'transparent'}
                          borderRadius='5px'
                    />
                  </HStack>
                </Box>
    
              </NavLink>
            )
          }
        })
      }
    </>
  )
}

export default SideBarSubLinks