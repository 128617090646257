import React from 'react'
import PropTypes from 'prop-types'
import FieldWrapper, { FieldWrapperPassThroughProps } from './FieldWrapper'
import { Icon, Input, InputGroup, InputLeftAddon, InputLeftElement, InputRightElement, useDisclosure } from '@chakra-ui/react'
import { FiEye } from "react-icons/fi"
import {UseFormRegisterReturn} from "react-hook-form"
import If from 'common/If'

type InputFieldProps = FieldWrapperPassThroughProps  & {
    registration : Partial<UseFormRegisterReturn> , 
    inputStyle?: any , 
    defaultValue?:any,
    type?:string,
    inputLeftAddon?:any
}


const InputField = (
   {
    registration , 
    error , 
    label  , 
    defaultValue,
    type="text",
    inputStyle={},
    inputLeftAddon=null
   }:InputFieldProps

) => {
  const {isOpen: isShowPasswrd ,onToggle: OnToogleShowPassword  } =  useDisclosure()
  return (
    <FieldWrapper
      error={error}
      label={label}
    >
       <InputGroup
         {...inputStyle}
         type="password"
       >
        <If condition={inputLeftAddon}>
          <InputLeftAddon children={inputLeftAddon?.children ??""}/>
        </If>
        <If condition={type=="password"}>
          <InputRightElement 
            cursor={"pointer"}
            onClick={OnToogleShowPassword}
          >
            <Icon 
            fontSize={"sm"}
              as={FiEye}
            />
          
          </InputRightElement>

        </If>
        <Input
          {...registration}
          defaultValue={defaultValue ??  ""}
          type={isShowPasswrd ? "text" : type}
        />
       </InputGroup>
    </FieldWrapper>
  )
}

InputField.propTypes = {}

export default InputField