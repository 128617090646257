

import  {useQuery, UseQueryResult} from "react-query"
import { axios } from "lib/axios"
import { ExtractFnReturnType , QueryConfig } from "lib/react-query"
import { extractOrder } from "../utils/extractData"
import { Order } from "../types"

type getOrdersQueryType = {
    order_id?: string
}


export const getOrder = async  (params : getOrdersQueryType ={}) :Promise<Order> =>{
    const response = await   axios.get(
        `/order/admin/single` , 
        {
            params : {
                order_id : {"eq"  : params.order_id} 
            }
        }
    )

    const data  =  response.data
    return (
        extractOrder(data??{})
    )


}


type FnType  =  typeof getOrder;





type  useOrdersOptions = {
    config? :any , 
    params?: getOrdersQueryType
}


export const  useOrder :({config , params }:useOrdersOptions)=>UseQueryResult<Order, Error> = ({config , params} = {})=>{
    
    
    return (
        useQuery(
            ["order"] , 
            ()=>getOrder(params) , 
            config
        )
    )

}

